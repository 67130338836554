import React, {Component} from "react"
import { Icon, Tooltip } from "antd"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { isInterviewDeveloper } from "../../../helperFunctions"
import "./OptionSelector.scss"


class OptionSelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showChatNotification: false
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.chat.length !== this.props.chat.length) {
			let new_message = this.props.chat[this.props.chat.length-1]
			if (!new_message.type && this.props.editorExpand) {
				this.setState({showChatNotification: true})
			}
		}
	}

	handleClick = (option) => {
		this.props.setOption(option)
		if (option === 'chat') {
			this.setState({showChatNotification: false})
		}
		this.props.editorCollapse()
	}
	render() {
		return (
			<div styleName="c-option-selector">
				{this.props.participants && <ul styleName="c-option-selector__list">
					<li styleName="c-option-selector__list-item" key={1}>
						<div
							styleName="c-option-selector__icon"
							onClick={() => this.handleClick('meeting')}
						>
							<Tooltip placement="topLeft" title="Users" mouseEnterDelay={0.5}>
								<Icon type="team"/>
							</Tooltip>
						</div>
						<div styleName="c-option-selector__marker-div">
							<span styleName="c-option-selector__marker" ck_selected={this.props.currOption === "meeting"?"true":"false"}/>
							<span styleName="c-option-selector__marker-box" ck_selected={this.props.currOption === "meeting"?"true":"false"}/>
						</div>
					</li>
					<li styleName="c-option-selector__list-item" key={2}>
						<div
							styleName="c-option-selector__icon"
							onClick={() => this.handleClick('video')}
						>
							<Tooltip placement="topLeft" title="Video" mouseEnterDelay={0.5}>
								<Icon type="video-camera" />
							</Tooltip>
						</div>
						<div styleName="c-option-selector__marker-div">
							<span styleName="c-option-selector__marker" ck_selected={this.props.currOption === "video"?"true":"false"}/>
							<span styleName="c-option-selector__marker-box" ck_selected={this.props.currOption === "video"?"true":"false"}/>
						</div>
					</li>
					<li styleName="c-option-selector__list-item" key={3}>
						<div
							styleName="c-option-selector__icon"
							onClick={() => this.handleClick('chat')}
						>
							<Tooltip placement="topLeft" title="Chat" mouseEnterDelay={0.5}>
								<Icon type="message"/>
							</Tooltip>
							{this.state.showChatNotification && <span styleName="notification__icon"></span>}
						</div>
						<div styleName="c-option-selector__marker-div">
							<span styleName="c-option-selector__marker" ck_selected={this.props.currOption === "chat"?"true":"false"}/>
							<span styleName="c-option-selector__marker-box" ck_selected={this.props.currOption === "chat"?"true":"false"}/>
						</div>
					</li>
					{isInterviewDeveloper(this.props.participants) && <li styleName="c-option-selector__list-item" key={4}>
						<div
							styleName="c-option-selector__icon"
							onClick={() => this.handleClick('notes')}
						>
							<Tooltip placement="topLeft" title="Notes" mouseEnterDelay={0.5}>
								<Icon type="carry-out"/>
							</Tooltip>
						</div>
						<div styleName="c-option-selector__marker-div">
							<span styleName="c-option-selector__marker" ck_selected={this.props.currOption === "notes"?"true":"false"}/>
							<span styleName="c-option-selector__marker-box" ck_selected={this.props.currOption === "notes"?"true":"false"}/>
						</div>
					</li>}
				</ul>}
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
	};
};

const mapStateToProps = state => {
	return {
		user_id: state.auth.user_id,
		participants: state.interview.participants
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OptionSelector));