import React, { useEffect, useState } from 'react';
import {
  Roster,
  RosterHeader,
  RosterGroup,
  useRosterState
} from 'amazon-chime-sdk-component-library-react';
import useMeetingEndRedirect from "../hooks/useMeetingEndRedirect"
import RosterAttendeeWrapper from '../../../../components/Meeting/RoasterAttendeeWrapper';

import "./MeetingRoster.scss"
import Spinner from '../../../../components/UI/Spinner/Spinner';


const MeetingRoster = ({ participants }) => {
  useMeetingEndRedirect()

  const { roster } = useRosterState();
  const [filter, setFilter] = useState('');

  let attendees = Object.values(roster).filter((attendee) => !attendee.externalUserId.includes("aws:"));
  participants = participants.filter((participant) => {
    let present = false;
    attendees.forEach((attendee) => {
      if (attendee.name === participant.name) present = true;
    });
    return !present;
  });

  if (filter) {
    attendees = attendees.filter((attendee) =>
      attendee?.name.toLowerCase().includes(filter.trim().toLowerCase())
    );
  }

  const handleSearch = (e) => {
    setFilter(e.target.value);
  };

  const attendeeItems = attendees.map((attendee) => {
    const { chimeAttendeeId } = attendee || {};
    return <RosterAttendeeWrapper key={chimeAttendeeId} attendeeId={chimeAttendeeId} />;
  });

  return (
    <>
      <Roster styleName="roster">
        <RosterHeader
          title="Present"
          badge={attendees.length}
        />
        {attendeeItems.length > 0 ? <RosterGroup>{attendeeItems}</RosterGroup> : <Spinner />}
        {participants.length > 0 ? (
          <div>
            <div styleName="participants_title">Yet to join</div>
            <div styleName='participants'>
              {participants.map((participant) => {
                return (
                  <div>
                    {participant.name}
                  </div>
                )
              })}
            </div>
          </div>
        ) : (<></>)}
      </Roster>
    </>
  );
};

export default MeetingRoster;
