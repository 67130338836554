import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "antd";

import * as actions from "../../store/actions";
import HomePageContainer from "../HomePageContainer/HomePageContainer";
import InviteUIContainer from "../InviteUI/InviteUIContainer";
import AssessmentContainer from "../Assessment/AssessmentContainer";
import ProblemContainer from "../Problem/ProblemContainer";
import ErrorContainer from "../Error/ErrorContainer";
import InterviewInviteUIContainer from "../InterviewInviteUI/InterviewInviteUIContainer";
import BaseInterviewContainer from "../Interview/BaseInterviewContainer";
import FeedbackContainer from "../Interview/Feedback/FeedbackContainer";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Wrapper from "../../HOC/Wrapper";
import TestComplete from "../../components/Problem/TestComplete";
import InterviewComplete from "../../components/Interview/InterviewComplete";
import { history } from "../../index";
import { AssessmentStatus } from "../../store/reducers/InviteReducer";

import FlashMessageList from "../../components/FlashMessageList/FlashMessageList";
import ErrorImage from "../../components/ErrorImage/ErrorImage";
import {
  problemsSelector,
  attemptedCountSelector,
} from "../../store/reducers/AssessmentReducer";

import "./MainContainer.scss";

const { confirm, info, warning } = Modal;

let browserPrefixes = ["moz", "ms", "o", "webkit"],
  isVisible = true; // internal flag to prevent multiple  fires, defaults to true

let hiddenPropertyName = null,
  visibilityEventName = null;

// get current browser vendor for visibilityAPI
for (var i = 0; i < browserPrefixes.length; i++) {
  let getHiddenPropertyName = browserPrefixes[i] + "Hidden";
  if (getHiddenPropertyName in document) {
    hiddenPropertyName = getHiddenPropertyName;
    visibilityEventName = browserPrefixes[i] + "visibilitychange";
    break;
  }
  hiddenPropertyName = "hidden";
  visibilityEventName = "visibilitychange";
}

class MainContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      endInterview:false
    }
  }

  modalTriggerHandler = () => {
    if (this.props.showAuthModal) this.props.onToggleAuthModal("");
    this.props.onLoadModal({
      showModal: false,
      modalType: "",
      modalContent: null,
    });
  };

  handleLeavePage = (e) => {
  sessionStorage.setItem('tab_closed', true) 
   this.props.tabClose()
  }

  shouldComponentUpdate(nextProps) {                          
    if (window.location.pathname.substring(1, 5) === "http") {
      window.open(
        window.location.pathname.substring(
          1,
          window.location.pathname.length + 1
        )
      );
      nextProps.location.pathname = this.props.location.pathname;
      nextProps.history.goBack();
      return false;
    }
    return true;
  }

  componentDidMount() {
    let urlparams = new URLSearchParams(this.props.location.search)                   
    if(!localStorage.getItem("company_id")){
      if (urlparams.get("company_id"))
        localStorage.setItem("company_id", urlparams.get("company_id"))
    }
    this.props.fetchCompanyDetails()
  }

  componentDidUpdate(prevProps) {
    if (this.props.assessment_status === AssessmentStatus.InProgress && this.props.assessment_status !== prevProps.assessment_status  && this.props.assessment_id) {
      window.addEventListener('beforeunload', this.handleLeavePage);
      document.addEventListener(
        visibilityEventName,
        this.handleVisibilityChange,
        false
      );
      window.addEventListener("focus", this.handleFocusBlur, false);
      window.addEventListener("blur", this.handleFocusBlur, false);

      if (sessionStorage.getItem('tab_closed')) {
        this.props.refreshTab()
        sessionStorage.removeItem('tab_closed')
      }
    } else if (this.props.assessment_status === AssessmentStatus.Completed && this.props.assessment_status !== prevProps.assessment_status) {
      document.removeEventListener(
        visibilityEventName,
        this.handleVisibilityChange
      );
      window.removeEventListener("focus", this.handleFocusBlur);
      window.removeEventListener("onblur", this.handleFocusBlur);
      window.removeEventListener('beforeunload', this.handleLeavePage);
    }
    if (this.props.modal.showModal) this.documentLostFocusModal();
  }

  handleFocusBlur = (event) => {
    if (event.type === "blur") return this.handleVisibilityChange(false);
    else return this.handleVisibilityChange(true);
  };

  handleVisibilityChange = (forcedFlag) => {
    //when blur/focus is fired
    if (typeof forcedFlag === "boolean") {
      if (forcedFlag) {
        return this.onVisible();
      }
      return this.onHidden();
    }
    //when visibilityChangeAPI is fired
    else {
      if (document[hiddenPropertyName]) {
        return this.onHidden();
      }
      return this.onVisible();
    }
  };

  onHidden = () => {
    if (isVisible) {
      //change the flag state
      isVisible = false;
      this.props.documentLostFocus();
    }
  };

  onVisible = () => {
    if (!isVisible) {
      //change the flag state
      isVisible = true;
    }
  };

  submitTest = (assessmentId, testEnded = false) => {
    if (!testEnded) {
      let message = "";
      if (this.props.attemptedCount < this.props.totalCount) {
        message = `You have not attempted ${
          this.props.totalCount - this.props.attemptedCount
        } problems.`;
      }
      message = `${message} You will not be able to make any further changes, your submission will be final.`;
      confirm({
        title: "Are you sure you want to submit the test?",
        content: message,
        onOk: () => {
          this.props.submitAssessment(assessmentId, true);
        },
        onCancel: () => {
          console.log("Cancel");
        },
      });
    } else {
      this.props.submitAssessment(assessmentId, false);
      info({
        title: "Time is up!",
        content: <p>Your test has been submitted</p>,
        onOk: () => history.push("/testcomplete"),
      });
    }
  };

  submitInterview = () => {
    confirm({
      title: "Are you sure you want to end the interview?",
      content: "The interview will end for all the users connected to this interview.",
      onOk: () => {
        this.setState({
          endInterview:true
        })
      },
      onCancel: () => {
        console.log("Cancel");
      },
    });
  }

  documentLostFocusModal = () => {
    let hasClass = document.getElementsByClassName("ant-modal ant-modal-confirm ant-modal-confirm-warning");
    //checking if modal is already open or not
    if (hasClass.length <= 0) {
      warning({
        title: this.props.modal.modalType,
        content: this.props.modal.modalContent,
        onOk: () =>
          this.props.onLoadModal({
            showModal: false,
            modalType: "",
            modalContent: null,
          }),
      });
    }
  };

  render() {
    let full = "false";
    let partialHeader = "false"
    if (
      window.location.pathname.includes("/set_password/") ||
      window.location.pathname.includes("/testcomplete") ||
      window.location.pathname.includes("/interviewcomplete") ||      
      window.location.pathname.includes("/interviewinvite") ||
      window.location.pathname === "/invite" ||
      window.location.pathname === "/error"
    ) {
      full = "true";
    }
    if(window.location.pathname.includes("/interview")) {
      partialHeader="true"
    }

    return (
      <React.Fragment>
        <FlashMessageList />
        <div styleName="c-layout">
          {full === "true" ? null : (
            <div styleName="c-layout__header">
              <Header
                attemptedCount={this.props.attemptedCount}
                totalCount={this.props.totalCount}
                remainingTime={this.props.remainingTime * 1000}
                assessmentId={this.props.assessmentId}
                submitTest={this.submitTest}
                timerWarnLevel={this.props.timerWarnLevel}
                setTimerExpLevel={this.props.setTimerExpLevel}
                partialHeader={partialHeader}
                participants={this.props.participants}
                submitInterview={this.submitInterview}
              />
            </div>
          )}
          <div styleName="c-layout__content" full={full}>
            <Switch>
              {this.props.notFound ? (
                <Route
                  render={(props) => (
                    <ErrorImage
                      {...props}
                      errorType={this.props.errorType}
                      errorHeading={this.props.errorHeading}
                      errorMessage={this.props.errorMessage}
                    />
                  )}
                />
              ) : null}
              <Route path="/home" exact component={HomePageContainer} />
              <Redirect exact from="/" to="/home" />
              <Route
                path="/assessment/:id"
                exact
                component={AssessmentContainer}
              />
              <Route
                path="/invite"
                exact
                render={(props) => <InviteUIContainer {...props} />}
              />
              <Route
                path="/assessment/:assessment_id/problem/:problem_id"
                exact
                component={ProblemContainer}
              />
              <Route
                path="/error"
                exact
                render={(props) => (
                  <Wrapper>
                    <ErrorContainer />
                  </Wrapper>
                )}
              />
              <Route
                path="/testcomplete"
                exact
                render={(props) => (
                  <Wrapper>
                    <TestComplete testTitle={this.props.test_title} />
                  </Wrapper>
                )}
              />
              <Route
                path="/interviewcomplete"
                exact
                render={(props) => (
                  <Wrapper>
                    <InterviewComplete />
                  </Wrapper>
                )}
              />
              <Route
                path="/interviewinvite"
                exact
                render={(props) => (
                  <InterviewInviteUIContainer />
                )}
              />             
              <Route
                path="/interview"
                exact
                render={(props) => 
                  (
                    <BaseInterviewContainer {...props} endInterview={this.state.endInterview}/>
                  )
                }
              />
              <Route
                path="/interview/feedback"
                exact
                render={(props) => (
                  <FeedbackContainer />
                )}
              />  
              <Route
                render={(props) => (
                  <ErrorImage
                    {...props}
                    errorType="404"
                    errorHeading="Link not found"
                    errorMessage="THE REQUESTED URL WAS NOT FOUND"
                  />
                )}
              />

            </Switch>
          </div>
          {full === "true" ? null : (
            <div styleName="c-layout__footer">
              <Footer />
            </div>
          )}
        </div>

        {this.props.modal.showModal ? (
          <Modal
            showModal={this.props.modal.showModal}
            onClickHandler={this.modalTriggerHandler}
          >
            {this.props.modal.modalContent}
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

MainContainer.propTypes = {
  modal: PropTypes.object,
  onLoadModal: PropTypes.func,
  showAuthModal: PropTypes.string,
  onToggleAuthModal: PropTypes.func,
  location: PropTypes.object,
  user_detail: PropTypes.object,
  notFound: PropTypes.bool,
  errorType: PropTypes.string,
  errorHeading: PropTypes.string,
  errorMessage: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    modal: state.global.modal,
    showAuthModal: state.global.showAuthModal,
    user_detail: state.auth,
    notFound: state.global.notFound,
    errorType: state.global.errorType,
    errorHeading: state.global.errorHeading,
    errorMessage: state.global.errorMessage,
    attemptedCount: attemptedCountSelector(state.assessment),
    totalCount: problemsSelector(state.assessment).length,
    remainingTime: state.assessment.remainingTime,
    assessmentId: state.assessment.id,
    participants: state.interview.participants,
    test_title: state.assessment.test_title,
    interview_id:state.interview.interview_id,
    assessment_status: state.invite.assessment_status,
    assessment_id: state.invite.assessment_id,
    timerWarnLevel: state.assessment.timerWarnLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadModal: (modal) => dispatch(actions.onLoadModal(modal)),
    onToggleAuthModal: (showAuthModal) =>
      dispatch(actions.onToggleAuthModal(showAuthModal)),
    submitAssessment: (assessmentId, redirect) =>
      dispatch(actions.submitAssessment(assessmentId, redirect)),
    setTimerExpLevel: (level) =>
      dispatch(actions.setTimerExpLevel({ level: level })),
    documentLostFocus: () => dispatch(actions.documentLostFocus()),
    tabClose: () => dispatch(actions.tabClose()),
    refreshTab: () => dispatch(actions.refreshTab()),
    fetchCompanyDetails: () => dispatch(actions.fetchCompanyDetails())
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainContainer)
);
