import { handleActions } from "redux-actions";

const initialState = {
    editor_view: "editor",
    screen_share: false
}

const MeetingReducer = handleActions(
    {
        SET_MEETING_DATA: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        RESET_MEETING_DATA: () => {
            return {
                ...initialState
            }
        },
        SET_CURRENT_PAGE: (state, action) => {
            return {
                ...state,
                current_page: action.payload
            }
        },
        SET_EDITOR_VIEW: (state, action) => {
            return {
                ...state,
                editor_view: action.payload
            }
        },
    },
        initialState
    )

export default MeetingReducer