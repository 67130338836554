import React, { useEffect, useState } from "react";
import { useContentShareState, useRosterState } from "amazon-chime-sdk-component-library-react";

export const ScrenShareStatus = () => {
    const { sharingAttendeeId } = useContentShareState();
    const { roster } = useRosterState();
    const attendees = Object.values(roster);
    const [screenSharingParticipant, setScreenSharingParticipant] = useState();

    useEffect(() => {
        attendees.forEach((attendee) => {
            if (attendee.chimeAttendeeId+"#content" === sharingAttendeeId) {
                setScreenSharingParticipant(attendee.name);
            }
        });
    }, [sharingAttendeeId]);

    return sharingAttendeeId ? (
        <div>{screenSharingParticipant} is sharing screen</div>
    ) : (
        <></>
    )
}