import React, { Component } from 'react'
import { Icon, List } from 'antd'
import * as actions from "../../../store/actions"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import moment from "moment";
import TextareaAutosize from 'react-autosize-textarea';

import "./ViewNotes.scss"

class ViewNotes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			curr_note: '',
			editMode: ''
		}
	}

	handleChange = (e) => {
		this.setState({curr_note: e.target.value})
	}

	handleEdit = (note_id) => {
		this.setState({editMode: note_id})

	}

	handleCancel = (note_id) => {
		this.setState({editMode: '', curr_note: ''})
	}

	handleDelete = (note_id, fire_time) => {
		this.props.deleteNote(note_id, fire_time)
		this.setState({editMode: '', curr_note: ''})
	}

	handleSave = (note_id, fire_time) => {
		if (!this.state.curr_note)
			this.handleDelete(note_id, fire_time)
		this.props.editNote(note_id, fire_time, this.state.curr_note)
		this.setState({editMode: '', curr_note: ''})
	}

	render() {
		return (
			<div styleName='c-notes-list'>
				<List
					styleName='notes-list'
					dataSource={this.props.notes}
					renderItem={item => ( 
						<List.Item
							key = {item.id}
						>
							<div styleName="notes-list__item">
									{item.id === this.state.editMode?
										<TextareaAutosize styleName="notes-list__itemdata-edit" onChange={this.handleChange} defaultValue={item.data}></TextareaAutosize>
										:
										<pre styleName="notes-list__itemdata-view">{item.data}</pre>
									}
								<div styleName="notes-list__footer">
									<div styleName="footer-time">
										{item.id === this.state.editMode?<span>Edit mode</span>:<span>{moment(item.fire_time).format("HH:mm")}</span>}
									</div>
									{item.id === this.state.editMode ? <div styleName="footer-options__modify">
										<span styleName="footer-options__cancel" onClick={() => this.handleCancel(item.id)}>
											<Icon type="close-square" />
											<span>Cancel</span>
										</span>
										<span styleName="footer-options__delete" onClick={() => this.handleSave(item.id, item.fire_time)}>
											<Icon type="save" />
											<span>Save</span>
										</span>
									</div>
									:
									<div styleName="footer-options__view">
										<span styleName="footer-options__edit" onClick={() => this.handleEdit(item.id)}>
											<Icon type="edit" />
											<span>Edit</span>
										</span>
										<span styleName="footer-options__delete" onClick={() => this.handleDelete(item.id, item.fire_time)}>
											<Icon type="delete" />
											<span>Delete</span>
										</span>
									</div>}
								</div>
							</div>
						</List.Item>
					)}
				/>
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		editNote : (note_id, fire_time, data) => dispatch(actions.editNote(note_id, fire_time, data)),
		deleteNote : (note_id, fire_time) => dispatch(actions.deleteNote(note_id, fire_time))
	};
};

const mapStateToProps = state => {
	return {
		notes: state.events.notes
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewNotes));