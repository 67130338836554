import { createAction } from "redux-action";
import axios from "axios";
import { AxiosCallError, AxiosCallRequest, AxiosCallSuccess} from "./commonActions";
import { get_service_endpoint } from "../../ServiceEndpoints.js";

let ep = get_service_endpoint("interview")

const setAttendeeData = createAction('SET_ATTENDEE_DATA')
export const resetAttendeeData = createAction('RESET_ATTENDEE_DATA')

export const fetchAttendee = (meeting_id, participant_id) => {
	return dispatch => {
        dispatch(AxiosCallRequest())
		let url=`${ep}/api/meeting/${meeting_id}/attendee/?participant_id=${participant_id}`
		return axios.get(url)
		.then(res => {
            dispatch(setAttendeeData(res.data))
            dispatch(AxiosCallSuccess())
		})
		.catch(error => {
           dispatch(AxiosCallError(error))
		})
	}
}