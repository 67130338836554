import { handleActions } from "redux-actions";

const initialState = {}

const AttendeeReducer = handleActions(
    {
        SET_ATTENDEE_DATA: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        RESET_ATTENDEE_DATA: (state, action) => {
            return {
                ...initialState
            }
        },
    },
        initialState
    )

export default AttendeeReducer