import React from 'react';
import {
  LocalVideo,
  FeaturedRemoteVideos,
  useLocalVideo
} from 'amazon-chime-sdk-component-library-react';

import "./video.scss"

const VideoView = () => {
  const { isVideoEnabled } = useLocalVideo();

  return (
    <div styleName="video-layout">
      <FeaturedRemoteVideos />
      {isVideoEnabled ? <LocalVideo /> : (<></>)}
    </div>
  );
};

export default VideoView;
