import React, { Component } from "react";
import { Editor as MonacoEditor } from '@monaco-editor/react';
import { constrainedEditor } from "./../../../node_modules/constrained-editor-plugin/dist/esm/constrainedEditor.js";

import Header from './Header';
import Footer from './Footer';
import "./Editor.scss";

const mode = {
	python3: "python",
	javascript: "javascript",
	c: "c",
	cpp: "cpp",
	java: "java",
	ruby3: "ruby"
}
class Editor extends Component {

	constructor(props) {
		super(props);
		this.state = {
			theme: "vs-light",
			model: null,
			editor: null,
			constrainedInstance: null,
			url: window.location.href
		}
	}

	componentDidMount() {
	}

	componentDidUpdate(previousProps) {
		if(this.state.model && (previousProps.lang !== this.props.lang || (this.state.url !== window.location.href && this.props.code !== previousProps.code))) {
			this.state.model.setValue(this.props.code);
			let restrictions = [];
		    restrictions.push({
				range: [this.props.headerSize + 2, 1, this.state.model.getLineCount() - this.props.footerSize - (this.props.lang === 'python3'? 0 : 1), 
					this.state.model.getLineMaxColumn(this.state.model.getLineCount() - this.props.footerSize - (this.props.lang === 'python3'? 0 : 1))],
			    allowMultiline: true
		    });
			this.state.model.updateRestrictions(restrictions);
			this.state.model.toggleHighlightOfEditableAreas();
			this.state.model.toggleHighlightOfEditableAreas();
			if(this.state.url !== window.location.href && this.props.code !== previousProps.code) this.setState({url: window.location.href});
		}
	}

	onCodeInEditorChange = (code) => {
		this.props.updateCode(code)
	}

	toggleTheme = (checked) => {
		if(checked) {
			this.setState({theme: "vs-light" });
		} else {
			this.setState({theme: "vs-dark" });
		}
	}

	handleEditorMount = (editor, monaco) => {
		let restrictions = [];
		const constrainedInstance = constrainedEditor(monaco);
		const model = editor.getModel();
		this.setState({model: model});
		this.setState({editor: editor});
		this.setState({constrainedInstance: constrainedInstance});
		constrainedInstance.initializeIn(editor);

		const endLine = model.getLineCount() - this.props.footerSize - (this.props.lang === 'python3'? 0 : 1);
		const startLine = this.props.headerSize + 2;

		restrictions.push({
			range: [ startLine <= model.getLineCount() ? startLine : 1, 1, endLine >= startLine ? endLine : 1, 
			model.getLineMaxColumn(endLine > 0 ? endLine : 1 )],
			allowMultiline: true
		});				

		constrainedInstance.addRestrictionsTo(model, restrictions);
		if(!model._hasHighlight) model.toggleHighlightOfEditableAreas();
	}

	render() {
		return(
			<div styleName="c-editor">
				<Header
					selectedLanguage={this.props.selectedLanguage}
					editorExpand={this.props.editorExpand}
					languageList={this.props.languageList}
					switchLanguage={this.props.switchLanguage}
					toggleExpand={this.props.toggleExpand}
					toggleTheme={this.toggleTheme}
				/>
				<div styleName="c-editor__ace">
					<MonacoEditor
						language={mode[this.props.lang]}
						theme={this.state.theme}
						onChange={this.onCodeInEditorChange}
						options={{
							highlightActiveLine: false,
							showPrintMargin: false,
							dragAndDrop: false,
							tabSize: 4,
							scrollBeyondLastLine: false,
							renderFinalNewline: false,
							minimap: {
								enabled: false,
							},
						}}
						value={this.props.code}
						height="100%"
						width="100%"
						onMount={this.handleEditorMount}
					/>
				</div>
				<Footer submitCode={this.props.submitCode} disabled={this.props.disabled} />
			</div>
		)
	}
}

export default Editor;