import { createAction } from "redux-action";
import axios from "axios";
import { AxiosCallError, AxiosCallRequest, AxiosCallSuccess} from "./commonActions";
import { logout } from "./InterviewActions";
import { resetAttendeeData } from "./AttendeeActions";
import { get_service_endpoint } from "../../ServiceEndpoints.js";

let ep = get_service_endpoint("interview")

const setMeetingData = createAction('SET_MEETING_DATA')
const resetMeetingData = createAction('RESET_MEETING_DATA')
export const setCurrentInterviewPage = createAction('SET_CURRENT_PAGE')
export const setEditorView = createAction('SET_EDITOR_VIEW')

export const fetchMeeting = (interview_id) => {
	return dispatch => {
        dispatch(AxiosCallRequest())
		let url=`${ep}/api/interview/${interview_id}/meeting/`
		return axios.get(url)
		.then(res => {
            dispatch(setMeetingData(res.data))
            dispatch(AxiosCallSuccess())
		})
		.catch(error => {
            if (error.response.status === 404)
                dispatch(createMeeting(interview_id))
            else dispatch(AxiosCallError(error))
		})
	}
}

export const createMeeting = (interview_id) => {
	return dispatch => {
        dispatch(AxiosCallRequest())
		let url=`${ep}/api/interview/${interview_id}/meeting/`
		return axios.post(url)
		.then(res => {
            dispatch(setMeetingData(res.data))
            dispatch(AxiosCallSuccess())
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
		})
	}
}

export const expireMeeting = (meeting_id) => {
	return dispatch => {
		dispatch(AxiosCallRequest())
		let url=`${ep}/api/meeting/${meeting_id}/`
		return axios.patch(url, {"status": "IN"})
		.then(res => {
			dispatch(logout())
			dispatch(resetMeetingData())
			dispatch(resetAttendeeData())
            dispatch(AxiosCallSuccess())
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
		})
	}
}

export const createRecording = (meeting_id) => {
	return dispatch => {
		dispatch(AxiosCallRequest())
		let url=`${ep}/api/recording/${meeting_id}/`
		return axios.post(url)
		.then(res => {
            dispatch(AxiosCallSuccess())
		})
		.catch(error => {
			dispatch(AxiosCallError(error))
		})
	}
}