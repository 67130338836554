import { LogLevel } from 'amazon-chime-sdk-js';


const SDK_LOG_LEVELS = {
    'debug': LogLevel.DEBUG,
    'info': LogLevel.INFO,
    'warn': LogLevel.WARN,
    'error': LogLevel.ERROR,
    'off': LogLevel.OFF,
}

const logLevel = SDK_LOG_LEVELS.warn;

const enableWebAudio = true;

const config = {
  logLevel,
  enableWebAudio,
};

export default config;
