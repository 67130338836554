import React from "react"
import { connect } from "react-redux"
import * as actions from "../../store/actions"
import { history } from "../../index";
import { isCookieExpired } from "../../helperFunctions"
import BaseMeetingContainer from "./Meeting/BaseMeetingContainer";
import Spinner from "../../components/UI/Spinner/Spinner";

class BaseInterviewContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            isDataLoaded: false
        }
    }

    componentDidMount() {
        let urlparams = new URLSearchParams(this.props.location.search)
        let interview_id = urlparams.get("interview_id")
        // user cred missing, redirect to login page
        if (!localStorage.getItem("access-token") || isCookieExpired()){
			history.push(`/interviewinvite?invterview_id=${interview_id}
				&hash_value=${localStorage.getItem('hash_value')}&role=${localStorage.getItem('role')}
				&company_id=${localStorage.getItem('company_id')}`)
		}
        else {
            if (!this.props.interview_status) { // interview data does not exist
                this.props.fetchInterviewInvite(interview_id, localStorage.getItem("hash_value"), localStorage.getItem("role"), localStorage.getItem("company_id"), true)
                .then(res => {
                    //check if interview is complete and feedback exists. If no, redirect to feedback page
                    if (this.props.interview_status === "COMPLETED" || this.props.interview_status === "CM") {
                        let curr_participant = this.props.participants.filter(p => p.email === localStorage.getItem("username"))[0]
                        if (curr_participant.role === "IN" && (!curr_participant.feedback || !curr_participant.rating))
                            history.push(`/interview/feedback?interview_id=${interview_id}`)
                    }
                    // get or create meeting
                    this.props.fetchMeeting(interview_id)
                })
            }
            else { // interview data exists
                // get or create meeting
                this.props.fetchMeeting(interview_id)
            }
            //fetch chat and notes data
            this.props.fetchData(interview_id)
            // get all languages supported by code editor
            this.props.getLanguages();
            // get latest editor code and language
            this.props.fetchLatestEditorEvent(interview_id, this.props.last_language)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.meeting_id !== this.props.meeting_id) {
            let email_id = localStorage.getItem("username")
            if (email_id) {
                let participant_id = this.props.participants.find(item => item.email === email_id).id
                this.props.fetchAttendee(this.props.meeting_id, participant_id).then(res => this.setState({isDataLoaded: true}))
            }
        }
    }

    render() { 
        return (
            <>
                {this.state.isDataLoaded ?<BaseMeetingContainer endInterview={this.props.endInterview}/>: <Spinner />}
            </>
        );
    }
}

const mapDispatchToProps = dispatch => {
	return {
        fetchInterviewInvite: (interview_id, hash_value, role, company_id, fetch_interview_data, callback) => 
            dispatch(actions.fetchInterviewInvite(interview_id, hash_value, role, company_id, fetch_interview_data, callback)),
        fetchData: (interview_id) => dispatch(actions.fetchData(interview_id)),
        getLanguages: () => dispatch(actions.getLanguages()),
        fetchLatestEditorEvent: (interview_id, language) => dispatch(actions.fetchLatestEditorEvent(interview_id, language)),
        fetchMeeting: (interview_id) => dispatch(actions.fetchMeeting(interview_id)),
        fetchAttendee: (meeting_id, participant_id) => dispatch(actions.fetchAttendee(meeting_id, participant_id))
	};
};

const mapStateToProps = state => {
	return {
        interview_id:state.interview.interview_id,
        interview_status:state.interview.status,
        participants: state.interview.participants,
        last_language: state.events.last_language,
        meeting_id: state.meeting.id
	};
};
 
export default connect(mapStateToProps, mapDispatchToProps)(BaseInterviewContainer);