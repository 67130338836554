import React, { Component } from 'react'
import { Icon } from 'antd'
import * as actions from "../../../store/actions"
import ViewNotes from './ViewNotes'
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"

import "./NotesContainer.scss"

class Notes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			note: ''
		}
	}

	handleChange = (e) => {
		this.props.setCurrNoteState(e.target.value)
	}

	addNote = () => {
		if (this.props.curr_note) {
			this.props.postNote(this.props.curr_note)
			this.props.setCurrNoteState('')
		}
	}
	render() {
		return (

			<div styleName='c-notes' >
				<div styleName='c-create-notes'>
					<div styleName='c-create-notes__header'>
						<p styleName='create-notes__header-left'>Create a new note</p>
						<div styleName='create-notes__header-right' onClick={this.addNote}>
							<Icon type="save"/>
							<span style={{paddingLeft:"0.5vw"}}>Save</span>
						</div>
					</div>
					<div styleName='c-create-notes__body'>
						<textarea placeholder="Enter your note here!" styleName="create-notes__body-textarea"onChange={this.handleChange} value={this.props.curr_note}/> 
					</div>
				</div>
				<div styleName='c-view-notes'>
					{this.props.notes.length > 0 && <ViewNotes/>}
			 	</div> 
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
	   postNote : (data) => dispatch(actions.postNote(data)),
	};
};

const mapStateToProps = state => {
	return {
      notes: state.events.notes
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notes));