import React from "react"
import { ThemeProvider } from 'styled-components';
import {
  lightTheme,
  MeetingProvider,
  NotificationProvider,
  VoiceFocusProvider,
  GlobalStyles,
} from 'amazon-chime-sdk-component-library-react';

import config from "./meetingConfig";

import ErrorProvider from "./Providers/ErrorProvider";
import Notifications from "./Notifications";
import MeetingContainer from "./MeetingContainer";


const BaseMeetingContainer = (props) => {
    return(
        <>
            <Theme>
                <NotificationProvider>
                    <Notifications />
                    <ErrorProvider>
                        <VoiceFocusProvider>
                            <MeetingProvider {...config}>
                                    <MeetingContainer endInterview={props.endInterview}/>
                            </MeetingProvider>
                        </VoiceFocusProvider>
                    </ErrorProvider>
                </NotificationProvider>
            </Theme>
        </>
    ) 
}

const Theme = ({ children }) => {

    return (
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        {children}
      </ThemeProvider>
    );
};

export default BaseMeetingContainer

