import React, { useEffect } from 'react';
import {
  useMeetingManager,
  useNotificationDispatch,
  Severity,
  ActionType,
} from 'amazon-chime-sdk-component-library-react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../..';
import { expireMeeting } from '../../../store/actions';

const NoMeetingRedirect = ({ children }) => {
  const meeting_id = useSelector(state => state.meeting.id)
  const dispatch = useDispatch()
  const dispatch_notification = useNotificationDispatch();
  const meetingManager = useMeetingManager();

  const payload = {
    severity: Severity.INFO,
    message: 'Meeting does not exist, redirecting to login page...',
    autoClose: true
  };

  useEffect(() => {
    return;
    if (!meetingManager.meetingSession) {
      dispatch_notification({
        type: ActionType.ADD,
        payload: payload
      });
      setTimeout(() => {
        const interview_id = localStorage.getItem('interview_id')
        const hash_value = localStorage.getItem('hash_value')
        const role = localStorage.getItem('role')
        const company_id = localStorage.getItem('company_id')
        dispatch(expireMeeting(meeting_id))
        // redirect to login page
        let redirect_url = `/interviewinvite?interview_id=${interview_id}&hash_value=${hash_value}&role=${role}&company_id=${company_id}`
        history.push(redirect_url)
      }, 1500)
    }
  }, [dispatch, meetingManager.meetingSession, payload]);

  return <>{children}</>;
};

export default NoMeetingRedirect;
