import { createAction } from "redux-action";
import axios from "axios";
import { AxiosCallError, AxiosCallRequest, AxiosCallSuccess, showErrorPage } from "./commonActions";
import { get_service_endpoint } from "../../ServiceEndpoints.js";
import {fetchData} from "../actions/InterviewActions"
import { history } from '../../index'
import { InterviewInviteStatus } from '../../store/reducers/InterviewInviteReducer'

let ep = get_service_endpoint("interview")

export const validateInterviewInviteSuccess = createAction("VALIDATE_INTERVIEW_INVITE_SUCCESS");


export const fetchInterviewInvite = (interview_id, hash_value, role, company_id, fetch_interview_data, callback) => {
    return (dispatch, getState) => {
        dispatch(AxiosCallRequest());
        let url=`${ep}/api/interviewinvite`;
        return axios.get(url,{
        params:{
                "interview_id":interview_id,
                "hash_value":hash_value,
                "role":role,
                "company_id":company_id
            }
        }).then(response => {
            if(response.success) {
                if (response.data.status === InterviewInviteStatus.CANCELLED) {
                    dispatch(showErrorPage({
                        errorType:"Application Error",
                        errorHeading:"Invite Cancelled",
                        errorMessage:"Please contact CodeHall for further assistance"
                    }))
                    history.push('/error')
                }
                else if (response.data.status === "CT" || response.data.status === "COMPLETED") {
                    if (!response.data.participants)
                        return history.push('/interviewcomplete')
                    let participants = response.data.participants.filter(p => p.role === "IN")
                    if (participants.filter(p => !p.feedback || !p.rating).length === 0)
                        history.push('/interviewcomplete')
                    else if (localStorage.getItem("username") && participants.some(p => p.email === localStorage.getItem("username"))) {
                        let user = participants.find(p => p.email === localStorage.getItem("username"))
                        if (user.feedback || user.rating)
                            history.push('/interviewcomplete')
                        else {
                            dispatch(validateInterviewInviteSuccess(response.data))
                            if(callback)
                                callback()
                        }
                    }
                    else {
                        dispatch(validateInterviewInviteSuccess(response.data))
                        if(callback)
                            callback()
                    }
                }
                else {
                    dispatch(validateInterviewInviteSuccess(response.data))
                    localStorage.setItem("hash_value", hash_value)
                    localStorage.setItem("role", role)
                    localStorage.setItem("company_id", company_id)

                    if(fetch_interview_data)
                        dispatch(fetchData(interview_id))
                    if(callback)
                        callback()
                }
                dispatch(AxiosCallSuccess())
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 404) {
                    dispatch(showErrorPage({
                        errorType:"Application Error",
                        errorHeading:"Invalid Interview Id",
                        errorMessage:"Please contact CodeHall for further assistance"
                    }))
                } else if (error.response.status === 400) {
                    dispatch(showErrorPage({
                        errorType:"Application Error",
                        errorHeading:"Invalid Signature",
                        errorMessage:"Please contact CodeHall for further assistance"
                    }))
                } else {
                    dispatch(showErrorPage({
                        errorType:"Application Error",
                        errorHeading:"Invalid Invite Id",
                        errorMessage:"An error occured while validating the invite"
                    }))
                }
                history.push('/error')
                dispatch(AxiosCallSuccess())
            }
        })
    };
};

export const startInterview = (interview_id) => {
    return  dispatch => {
            dispatch(AxiosCallRequest())
            let url=`${ep}/api/interview/${interview_id}/start_interview/`
            axios.get(url).then(
                res => {
                    dispatch(AxiosCallSuccess())
                }
            ).catch( error => {
                dispatch(AxiosCallError(error))
                }
            )
        history.push(`/interview?interview_id=${interview_id}`)
    }
};
