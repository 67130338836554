// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import {
  SpeakerSelection,
  useAudioOutputs,
  PrimaryButton
} from 'amazon-chime-sdk-component-library-react';

import TestSound from './TestSound';

const SpeakerDevices = () => {
  const { selectedDevice } = useAudioOutputs();
  const [selectedOutput, setSelectedOutput] = useState(selectedDevice);

  const handleChange = (deviceId) => {
    setSelectedOutput(deviceId);
  };

  const handleTestSpeaker = () => {
    new TestSound(selectedOutput);
  };

  return (
    <div style={{"marginTop": "1.5vw"}}>
      <SpeakerSelection onChange={handleChange} />
      <PrimaryButton onClick={handleTestSpeaker} style={{marginTop: "1vw", fontSize: "0.9rem"}} label="Test Speakers"/>
    </div>
  );
};

export default SpeakerDevices;
