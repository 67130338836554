import React, { Component } from "react";
import "./Header.scss"
import { connect } from "react-redux"
import { Progress, Button, Icon } from 'antd';
import Timer from 'react-compound-timer'
import { GACategories, GAActions} from "../../analytics"
import { handleGAEvent, isInterviewDeveloper } from "../../helperFunctions"
import { get_image_endpoint } from '../../ImageEndpoint'

class Header extends Component {

	handleSubmitTestButton = () => {
		 this.props.submitTest(this.props.assessmentId)
	}

	handleEndInterview = () => {
		this.props.submitInterview()
	}
	
	render() {
		if(this.props.participants){
			var isInterviewer = isInterviewDeveloper(this.props.participants)
		}
		let timer = null
		if (isNaN(this.props.remainingTime)) {
			timer = (<span></span>)
		} else if(this.props.remainingTime < 0) {
			timer = (<span>Expired</span>)
		} else {
			timer = (
				<Timer
					initialTime={this.props.remainingTime}
					direction="backward"
					formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
					checkpoints = {[
						{
							time: 0*60*1000,
							callback: () => this.props.submitTest(this.props.assessmentId, true),
						},
						{
							time: 5*60*1000,
							callback: () => this.props.setTimerExpLevel("red")
						},
						{
							time: 10*60*1000,
							callback: () => this.props.setTimerExpLevel("yellow")
						}
					]}
				>
				{() => (
					<React.Fragment>
						<Timer.Hours /> : <Timer.Minutes /> : <Timer.Seconds />
					</React.Fragment>
				)}
				</Timer>
			)
		}
		return (
			<div styleName="l-header">
				{ this.props.company_details.alias &&
				<div styleName="c-logo">
					<img src={get_image_endpoint(this.props.company_details.alias, "dark")} 
						styleName="c-logo__image"
						alt={this.props.company_details.name+" logo"}
						onClick={this.goToHomePage}
					/>
				</div>
				}
				{this.props.partialHeader === "false" ?
				<React.Fragment>
					<div styleName="c-problem-counter">
						<div styleName="c-problem-counter__progress_bar">
							<Progress
								percent={this.props.attemptedCount*100/this.props.totalCount}
								status="active"
								type="line"
								strokeColor="#fffff"
								strokeWidth={18}
								showInfo={false}
								strokeLinecap='square'
							/>
						</div>
						<p styleName="progress-counter"> {this.props.attemptedCount}/{this.props.totalCount} attempted </p>
					</div>
					<div styleName={`c-timer--${this.props.timerWarnLevel} c-timer`}>
						<Icon type="clock-circle" style={{fontSize:'1.9vw'}}/>
						<span>&nbsp;&nbsp;&nbsp;
							{timer}
						</span>
					</div>
					
					<Button
						type="primary" 
						size="large" 
						style={{backgroundColor:"#06c4ff", fontSize:"1.2rem", fontWeight:"500"}}
						onClick={handleGAEvent(this.handleSubmitTestButton, GACategories.assessment, GAActions.submitTest)}
					>
						Submit Test
					</Button>
				</React.Fragment> : 
				isInterviewer &&  !window.location.pathname.includes("/feedback") && this.props.current_interview_page === "meeting"? 
					<div>
						<Button
						type="primary" 
						size="large" 
						style={{fontSize:"1.2rem", fontWeight:"500", background: "#4DC6F3 0% 0% no-repeat padding-box",
								borderRadius: "5px",
								padding: "0.25vw 1vw",
								opacity: "1",
								width:"10.5vw"
							}}
						onClick={this.handleEndInterview}
					>
						End Interview
					</Button>
					</div>
				: null
				}

				
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		company_details: state.auth.company_details,
		current_interview_page: state.meeting.current_page
	};
};

export default connect(mapStateToProps,null)(Header);
