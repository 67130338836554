// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { history } from '../../../..';
import {
  MeetingStatus,
  useNotificationDispatch,
  Severity,
  ActionType,
  useMeetingStatus
} from 'amazon-chime-sdk-component-library-react';
import { expireMeeting } from '../../../../store/actions';


const useMeetingEndRedirect = () => {
  const meeting_id = useSelector(state => state.meeting.id)
  const dispatch_notification = useNotificationDispatch();
  const dispatch = useDispatch()
  const meetingStatus = useMeetingStatus();

  useEffect(() => {
    if (meetingStatus === MeetingStatus.Ended) {
      dispatch_notification({
        type: ActionType.ADD,
        payload: {
          severity: Severity.INFO,
          message: 'The meeting has ended, redirecting to login page...',
          autoClose: true,
          replaceAll: true
        }
      });
      setTimeout(() => {
        const interview_id = localStorage.getItem('interview_id')
        const hash_value = localStorage.getItem('hash_value')
        const role = localStorage.getItem('role')
        const company_id = localStorage.getItem('company_id')
        dispatch(expireMeeting(meeting_id)).then(() => {
           // redirect to login page
          let redirect_url = `/interviewinvite?interview_id=${interview_id}&hash_value=${hash_value}&role=${role}&company_id=${company_id}`
          history.push(redirect_url)
        })
      }, 1500)
    }
  }, [meetingStatus, dispatch]);
};

export default useMeetingEndRedirect;
