import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux";
import DeviceSetup from "./DeviceSetup/DeviceSetup";
import NoMeetingRedirect from "./NoMeetingRedirect";
import { useMeetingManager, UserActivityProvider, useLocalVideo, useContentShareState } from "amazon-chime-sdk-component-library-react";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { interviewRoleMap } from "../../../helperFunctions";
import { setCurrentInterviewPage, expireMeeting } from "../../../store/actions";
import InterviewContainer from "../InterviewContainer";
import './MeetingContainer.scss'


const MeetingContainer = (props) => {
    const dispatch = useDispatch()

    const meetingManager = useMeetingManager()
    const { isVideoEnabled } = useLocalVideo();
    const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();

    const { meeting } = useSelector(state => state)
    const { attendee } = useSelector(state => state)
    const { participants } = useSelector(state => state.interview)
    const { role } = useSelector(state => state.interview)
    const current_page = meeting.current_page || 'device-setup'

    useEffect(() => {
        async function joinMeeting() {
            meetingManager.getAttendee = async (chimeAttendeeId, externalUserId) => {
                let curr_participant = participants.find(p => p.id == externalUserId.split(".")[0])
                if (curr_participant)
                    return {
                        name: curr_participant.name
                    }
                return
            }
            await meetingManager.join(
                {
                    meetingInfo: {
                        "MeetingId": meeting.aws_meeting_id,
                        "Title": interviewRoleMap[role] + " Meeting",
                        "ExternalMeetingId": meeting.interview_id,
                        "MediaPlacement": meeting.aws_media_placement,
                    },
                    attendeeInfo: {
                        "AttendeeId": attendee.aws_attendee_id,
                        "ExternalUserId": attendee.participant_id,
                        "JoinToken": attendee.aws_join_token
                    }
                }
            )
            dispatch(setCurrentInterviewPage("device-setup"))
        }
        setTimeout(joinMeeting, 0)
    }, [])

    async function handleEndMeeting() {
        await meetingManager.leave()
        dispatch(expireMeeting(meeting.id))
    }

    return (
        <>
            {current_page === "device-setup" &&
                (<NoMeetingRedirect>
                    <div styleName="meeting-container">
                        <DeviceSetup />
                    </div>
                </NoMeetingRedirect>
                )
            }
            {current_page === "meeting" &&
                (<NoMeetingRedirect>
                    <UserActivityProvider>
                        <InterviewContainer endInterview={props.endInterview} endMeeting={handleEndMeeting} isVideoEnabled={isVideoEnabled}
                            isScreenSharing={sharingAttendeeId}
                        />
                    </UserActivityProvider>
                </NoMeetingRedirect>
                )
            }
            {!current_page && <Spinner />}
        </>
    )
}

export default MeetingContainer