import React from 'react';
import { Heading } from 'amazon-chime-sdk-component-library-react';
import { StyledLayout } from './Styled';
import DeviceSelection from '../../../../components/Meeting/DeviceSelection'



const DeviceSetup = () => {
    return (
        <StyledLayout>
            <Heading tag="h3" level={5} css="margin-top: -2vw">
                Device settings
            </Heading>
            <DeviceSelection />
        </StyledLayout>
    );
}

export default DeviceSetup;