import React from "react"
import { Editor as MonacoEditor } from '@monaco-editor/react';
import { Row, Col, Icon, Spin, Modal } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import InterviewHeader from './EditorHeader'
import { webSocket } from 'rxjs/webSocket';
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import Chat from './Chat/ChatContainer'
import Notes from './Notes/NotesContainer'
import OptionSelector from './OptionSelector/OptionSelector'
import * as actions from "../../store/actions"
import moment from "moment";
import  debounce from "lodash/debounce";
import { history } from "../../index";
import { get_service_endpoint } from "../../ServiceEndpoints.js";
import { XTerm } from 'xterm-for-react'
import { isCookieExpired } from "../../helperFunctions"
import './InterviewContainer.scss'
import MeetingRoster from "./Meeting/Roster/MeetingRoster";
import VideoView from "./Meeting/Videos"
import MeetingControls from "./Meeting/MeetingControls";
import ScreenShareView from "./Meeting/ScreenShare/ScreenShareView";
import { ScrenShareStatus } from "./Meeting/ScreenShare/ScreenShareStatus.js";
class InterviewContainer extends React.Component {

    constructor(props) {
        super(props)
        let urlparams = new URLSearchParams(this.props.location.search)
        this.state = {
            editorExpand: false,
            lang:"python3",
            editor_code: "",
            execute_status: "",
            stdout: "",
            stderr: "",
            chat: '',
            currOption: 'meeting',
            showOutput:false,
            isWSConnected: false,
            maximizeOutput:false,
            syncCursor: false,
            curr_row: '',
            curr_typing_user: '',
            curr_note: '',
            editor: null
        }

        let ep = get_service_endpoint("ws")
        this.interview_id = urlparams.get("interview_id")
        this.ws = webSocket(`${ep}/collab_editor/?interview_id=${this.interview_id}`);
        this.xtermRef = React.createRef();
    }

    componentDidMount() {
        if (!localStorage.getItem("access-token") || isCookieExpired()){
            history.push(`/interviewinvite?invterview_id=${localStorage.getItem('interview_id')}
                &hash_value=${localStorage.getItem('hash_value')}&role=${localStorage.getItem('role')}
                &company_id=${localStorage.getItem('company_id')}`)
        }
        window.addEventListener('online', this.handleUserConnection);
        window.addEventListener('offline', this.handleUserConnection);
        this.setState({editor_code: this.props.last_editor_data? this.props.last_editor_data: "", lang: this.props.last_language?this.props.last_language:"python3"}, () => {
            this.ws.subscribe((message) => {
                this.setState({isWSConnected: true })
                if (!this.interval)
                    this.interval = setInterval(this.sendHeartbeat, 15000);
                if (message.type === "rcv_heartbeat")
                    this.recieveHeartBeat(message.data)
                else if (message.type === "editor")
                    this.editorUpdate(message.data)
                else if (message.type === "end_typing")
                    this.endTyping(message.data)
                else if (message.type === "send_initial_cursor_position")
                    this.sendInitialCursorPosition()
                else if (message.type === "receive_initial_cursor_position")
                    this.setIntitialCursorPosition(message.data)
                else if (message.type === "presence")
                    this.handlePresence(message.data)
                else if (message.type === "execute")
                    this.showOutput(message.data)
                else if (message.type === "chat")
                    this.showChat(message.data)
                else if (message.type === "entry" || message.type === "exit")
                    this.showUserStatus(message)
                else if (message.type === "language")
                    this.languageUpdate(message.data)
                else if (message.type === "end_interview")
                    this.endInterview(message.data)
            })
        })
    }

    componentWillUnmount () {
        this.ws.unsubscribe()
        if (this.interval)
            clearInterval(this.interval)
        this.setState({isWSConnected: false})
        window.removeEventListener('online', this.handleUserConnection);
        window.removeEventListener('offline', this.handleUserConnection);
    }

    componentDidUpdate (prevProps) {
        if(!prevProps.endInterview && this.props.endInterview) {
            //end the interview
            this.handleEndInterview()
        }

        if(prevProps.heartbeat !== this.props.heartbeat) {
            this.checkLastHeartbeat()
        }

        if (!prevProps.isVideoEnabled && this.props.isVideoEnabled) {
            // local video started, switch to video tab
            this.setState({currOption: "video"})
        }
        if (prevProps.isVideoEnabled && !this.props.isVideoEnabled) {
            // local video stopped, switch back to meeting tab
            this.setState({currOption: "meeting"})
        }

        if (!prevProps.isScreenSharing && this.props.isScreenSharing) {
            // screen sharing started by someone else
            this.props.setEditorView("screen-share")
        }

        if (prevProps.isScreenSharing && !this.props.isScreenSharing) {
            // screen sharing stopped by someone else
            this.props.setEditorView("editor")
        }
    }

    checkLastHeartbeat = () => {
        let curr = moment().valueOf()
        if (curr-parseInt(this.props.heartbeat) > 2500) {
            this.handleOffline()
        }
    }

    sendHeartbeat = () => {
        this.ws.next({
            "type": "heartbeat", 
            "data": {}
        })
    }

    recieveHeartBeat = (data) => {
        this.props.setHeartbeat(data['fire_time'])
    }

    handlePresence = (data) => {
        if (this.props.participants) {
            let participants = this.props.participants.map(p => {
                let online = data.some(d => d === p.id)
                return {
                    ...p,
                    isActive: online
                }
            })
            this.props.setParticipants(participants)
        }
    }

    handleUserConnection = (event) => {
        if (event.type === 'online' && this.state.connection_status === 'offline') {
            this.props.checkNetworkConnection()
            .then (res => {
                window.location.reload()
            })
            .catch(err => {
                if (err.request)
                    this.handleOffline()
            })
        }
        else if (event.type === 'offline') {
            this.handleOffline()
        }
    }

    handleOffline = () => {
        if (this.interval)
            clearInterval(this.interval)
        this.ws.unsubscribe()
        this.setState({connection_status: 'offline', isWSConnected: false})
        Modal.warning({
            title: 'Network Error',
            content: "You have lost your internet connection.",
            okText: 'Dismiss',
            onOk: () => {}
        });
    }

    handleEndInterview = () => {
        this.ws.next({
            "type": "end_interview", 
            "data": {}
        })
    }

    setCurrNoteState = (note) => {
        this.setState({curr_note: note})
    }

    sendInitialCursorPosition = () => {
        let position = this.state.editor.getPosition()
        if (position && position.row !== '' && position.row !== null && position.row === parseInt(position.row, 10) && position.row >= 0
                && position.column !== '' && position.column !== null && position.column === parseInt(position.column, 10) && position.column >= 0
            ) {
            this.ws.next({
                "type": "send_initial_cursor_position", 
                "data": position
            })
        }
    }
     
    isCandidate = () => {
        let current_user_username = localStorage.getItem("username")
        return this.props.participants.some(e => e.email === current_user_username && e.role === "CA")
    }

    canSync = (cursor) => {
        if (!this.state.syncCursor)
            return false
        if (cursor.row === null || cursor.row === '' || cursor.column === null || cursor.column === '') {
            return false
        }
        if (cursor.row !== parseInt(cursor.row, 10))
            return false
        if (cursor.column !== parseInt(cursor.column, 10))
            return false
        if (cursor.row < 0 || cursor.column < 0)
            return false
        return true
    }

    setIntitialCursorPosition = (data) => {
        if (data && this.canSync(data))
            this.state.editor.gotoLine(data.row+1, data.column, true)
    }

    editorUpdate = (data) => {
        this.setState({editor_code: data.data})
        if (data.cursor && this.canSync(data.cursor))
            this.state.editor.gotoLine(data.cursor.row+1, data.cursor.column, true)
        let user_name = this.props.participants.find(item => item.email === data.user.email).name
        user_name = user_name.split(' ')[0]
        user_name = user_name.substring(0, 15)
        if (!this.state.curr_typing_user) {
            this.setState({curr_typing_user: user_name})
        }
    }

    languageUpdate = (data) => {
        this.props.fetchLatestEditorEvent(this.interview_id, data.language)
        .then(res => {
            if (this.props.last_editor_data)
                this.setState({editor_code: this.props.last_editor_data})
            else
                this.setState({editor_code: ''})
        })
        this.setState({lang: data.language})
    }

    showOutput = (data) => {
        this.setState({
            execute_status: data.status,
            showOutput:true
        }, () => {
            if(this.xtermRef.current){
            this.xtermRef.current.terminal.write(data.stdout.replace(/\n/g,"\r\n")+data.stderr.replace(/\n/g,"\r\n"))
            }
        })
    }

    closeOutput = () => {
        this.setState({
            showOutput:false,
            maximizeOutput:false
        })
    }

    showChat = (data) => {
        let chat_data = {
            "message": data.message,
            "user": this.props.participants.find(item => item.email === data.user.email).name,
            "user_id": data.user.user_id,
            "fire_time": moment(data.fire_time).format('YYYY-MM-DD HH:mm:ss')
        }
        this.setState({
            chat: [...this.state.chat, chat_data]
        })
    }

    endTyping = () => {
        this.setState({curr_typing_user: ''})
    }

    showUserStatus = (message) => {
        let user_status = {
            "type": message.type,
            "user": this.props.participants.find(item => item.email === message.data.user.email).name,
            "email": message.data.user.email,
            "user_id": message.data.user.user_id,
        }
        this.setState({
            chat: [...this.state.chat, user_status]
        })
    }
    endInterview = (data) => {
        let user = this.props.participants.find(e => e.email === data.email)
        this.props.setEndInterviewUserDetail({'user': user})
        localStorage.setItem("interviewer_name",user['name'])
        if (this.isCandidate())
            return history.push("/interviewcomplete")
        else {
            if (this.state.curr_note)
                return this.props.postNote(this.state.curr_note, this.goToEndInterviewPage)
            return this.goToEndInterviewPage()
        }
    }

    goToEndInterviewPage = () => {
        history.push(`/interview/feedback?interview_id=${this.interview_id}`)
        this.props.endMeeting().then(() => console.log("meeting ended"))
    }

    submitCode = () => {
        this.props.submitCode(this.state.editor_code, this.state.lang)
    }

    sendMessage = (message) => {
        this.ws.next ({
            "type": "chat",
            "data": {
                "message": message
            }
        })
    }

    onCodeInEditorChange = (code) =>  { 
        this.setState({editor_code: code}, () => {
            let cursor
            let position = this.state.editor.getPosition()
            if (position && position.row !== '' && position.row !== null && 
                    position.row === parseInt(position.row, 10) && position.row >= 0 &&
                    position.column !== '' && position.column !== null && 
                    position.column === parseInt(position.column, 10) && 
                    position.column >= 0
                )
                cursor = position
            this.ws.next({
                "type": "editor", 
                "data": 
                {
                    "language":this.state.lang, 
                    "cursor": cursor?cursor:'',
                    "data":code,
                }
            })
        })
        this.handleEndTyping()
    }
    
    toggleExpand = () => {
        this.setState((prevState, props) => ({
          editorExpand: !prevState.editorExpand,
        }));
    };

    switchLanguage = (language) => {
        this.ws.next ({
            "type": "language", 
            "data": 
            {
                "language":language, 
            }
        })
    };

    editorCollapse = () => {
        this.setState({editorExpand : false})
    }

    setOption = (option) => {
        this.setState({currOption: option})
    }

    resizeOutputView = () => {
        this.setState({
            maximizeOutput: !this.state.maximizeOutput
        }, () => {
            if(this.state.maximizeOutput){
                this.xtermRef.current.terminal.resize(110,33)
            }
            else {
                this.xtermRef.current.terminal.resize(110,7)
            }
        })
    }

    handleEndTyping = debounce(function() {
        this.ws.next({
            "type": "end_typing",
            "data": {}
        })
    },2000)


    syncCursor = (checked) => {
        this.setState({ syncCursor: checked})
        this.state.editor.focus()
        if (checked) {
            // get the cursor position of other clients as soon as someone turns on this setting
            this.ws.next({
                "type": "get_initial_cursor_position",
                "data": {}
            })
        }
    }

    render() {
        const mode = {
            python3: "python",
            javascript: "javascript",
            c: "c",
            cpp: "cpp",
            java: "java",
            ruby3: "ruby"
        }
       
        let editorTabSpan = 15;
        let chatTabSpan = 9;
        let selectorSpan = 0;
        let editorHeight = "0vw";
        let statusHeight = "0vw";

        if(this.props.participants){
            chatTabSpan = 8;
            editorTabSpan = 15;
            selectorSpan = 1;
        }

        if (this.state.editorExpand) {
            chatTabSpan = 0;
            editorTabSpan = 23;
            selectorSpan = 1
        }
        let participants_status = []
        if (this.props.participants && this.props.participants.length > 0) {
            this.props.participants.forEach(p => {
                if (p.email !== localStorage.getItem("username")) {
                    let u = p.name.split(' ')[0]
                    u = u.charAt(0).toUpperCase() + u.slice(1)
                    u = u.substring(0, 15) 
                    participants_status.push(
                    <div key={p.id}>
                        <span styleName="candidate-status__left-avatar">{p.name.split(' ')[0].charAt(0).toUpperCase()}</span>
                        <span styleName="candidate-status__left-icon" active={p.isActive?"true":"false"}></span>
                        <span styleName="candidate-status__left-text">{u}</span>
                    </div>
                    )
                }
                return;
            })
        }
        if (this.props.participants)
            {
                if (this.state.showOutput) {
                    if (this.state.maximizeOutput) {
                        editorHeight = "0vw";
                        statusHeight = "0vw"
                    }
                    else {
                        editorHeight = "23.9vw"
                        statusHeight = "0vw"
                    }
                }
                else  {
                    editorHeight = "29.9vw"
                    statusHeight = "3.14vw"
                }
            }
        else {
            if (this.state.showOutput) {
                if (this.state.maximizeOutput)
                    editorHeight = "0vw";
                else editorHeight = "20.9vw"
            }
            else editorHeight = "29.9vw"
        }

        return (
            <div styleName="c-interview">
                <Row
                    gutter={16}
                    style={{ marginRight: 0, marginLeft: 0, maxHeight: "82%"}}
                >
                    <Col
                        span={editorTabSpan}
                        style={{ paddingRight: 0, paddingLeft: 0,  }}
                    >
                    <div styleName="c-interview__code-editor">
                        <div style={{background: "#F8F8F8 0% 0% no-repeat padding-box",
                            border: "1px solid #707070",
                            opacity: "1"}}
                        >
                            
                        <InterviewHeader 
                            editorExpand = {this.state.editorExpand}
                            toggleExpand = {this.toggleExpand}
                            switchLanguage = {this.switchLanguage}
                            languageList = {this.props.supported_languages}
                            selectedLanguage = {this.state.lang}
                            submit = {this.submitCode}
                            syncCursor = {this.syncCursor}
                        />
                        </div>   
                        {this.props.editor_view === "editor" ?      
                        <MonacoEditor
						language={mode[this.state.lang]}
						theme = "vs-dark"
						onChange={this.onCodeInEditorChange}
						options={{
							highlightActiveLine: false,
							showPrintMargin: false,
							dragAndDrop: false,
							tabSize: 4,
							scrollBeyondLastLine: false,
							renderFinalNewline: false,
							minimap: {
								enabled: false,
							},
						}}
						value={this.state.editor_code}
						height={"60vh"}
						width="100%"
						onMount={(editor)=>{
                            this.setState({editor: editor});
                            editor.focus();
                        }}
                        />: <ScreenShareView />}
                        {!this.state.showOutput &&<div styleName="c-interview__code-editor__candidate-status" style={{height: statusHeight}}>
                            {this.state.isWSConnected && this.props.participants && <div styleName="candidate-status__left">
                                {participants_status}
                            </div>}
                            {this.props.participants && <p styleName="candidate-status__right">{this.state.curr_typing_user?this.state.curr_typing_user+" is typing":null}</p>}
                            {this.state.currOption=== 'video' && (
                            <div>
                                <MeetingControls css={`height: 3vw`} />
                            </div>)}
                        </div>}
                        { this.state.showOutput && this.state.execute_status === 'CT'? 
                        <div styleName={this.state.maximizeOutput ? "c-interview__code-editor__output-maximized" : "c-interview__code-editor__output-minimized"}>
                            <div style={{display:"flex", borderBottom: "2.8px solid grey", height:"2.4vw", justifyContent:"space-between"}}>
                                <p style={{paddingTop: "0.7vw",  display: "flex", marginLeft: "1vw"}}>Output </p>
                                <div style={{marginRight:"1.25vw"}}>
                                { (!this.state.maximizeOutput) ? <UpOutlined styleName="c-interview__output-icon-minmax" onClick={this.resizeOutputView}/> : <DownOutlined styleName="c-interview__output-icon-minmax" onClick={this.resizeOutputView}/> }
                                <Icon type="close" onClick={this.closeOutput} styleName="c-interview__output-icon-close"/>
                                </div>
                            </div>
                            <XTerm ref={this.xtermRef} options={{ rows:7, cols:110, rendererType:"dom" }}
                            />
                        </div>: this.state.showOutput &&
                        <div styleName="c-interview__code-editor__output-spinner">
                            <Spin />
                        </div>
                        }
                    </div>
                    </Col>
                    <Col
                        span={chatTabSpan}
                        style={{ paddingRight: 0, paddingLeft: 0, }}
                    >
                        {this.state.currOption=== 'meeting' && <div styleName="c-interview__chat">
                            <MeetingRoster participants={this.props.participants} />
                            <MeetingControls />
                        </div>}
                        {this.state.currOption=== 'video' && <div styleName="c-interview__chat" style={{height: "calc(7.64vw + 60vh)"}}>
                            <VideoView />
                            {/* <MeetingControls /> */}
                        </div>}
                        {this.state.currOption=== 'chat' && <div styleName="c-interview__chat">
                            <Chat sendMessage={this.sendMessage} chat={this.state.chat} connected={this.state.isWSConnected} chat_data={this.props.chat_data}/>
                        </div>}
                        {this.state.currOption=== 'notes' && <div styleName="c-interview__chat">
                            <Notes curr_note={this.state.curr_note} setCurrNoteState={this.setCurrNoteState}/>
                        </div>}
                    </Col>
                    {(this.state.editorExpand || this.props.participants)?<Col
                        span={selectorSpan}
                        style={{ paddingRight: 0, paddingLeft: 0, height:"100%" }}
                    >
                        <div styleName="c-interview__selector">
                            <OptionSelector 
                                setOption={this.setOption} 
                                currOption={this.state.currOption}
                                chat={this.state.chat} 
                                editorExpand={this.state.editorExpand}
                                editorCollapse={this.editorCollapse}
                            />
                        </div>
                    </Col>:null}
                </Row>
                <div styleName="screen-share-status">
						<ScrenShareStatus />
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => {
	return {
        submitCode: (code, language) => dispatch(actions.submitInterviewCode(code, language)),
        fetchInterviewInvite: (interview_id, hash_value, role, company_id, fetch_interview_data, callback) => 
            dispatch(actions.fetchInterviewInvite(interview_id, hash_value, role, company_id, fetch_interview_data, callback)),
        fetchData: (interview_id) => dispatch(actions.fetchData(interview_id)),
        setLastCode: (data) => dispatch(actions.setLastCode(data)),
        setLastLanguage: (data) => dispatch(actions.setLastLanguage(data)),
        getLanguages: () => dispatch(actions.getLanguages()),
        setEndInterviewUserDetail: (user) => dispatch(actions.setEndInterviewUserDetail(user)),
        setParticipants: (participants) => dispatch(actions.setParticipants(participants)),
        postNote : (data, callback) => dispatch(actions.postNote(data, callback)),
        fetchLatestEditorEvent: (interview_id, language) => dispatch(actions.fetchLatestEditorEvent(interview_id, language)),
        checkNetworkConnection: () => dispatch(actions.checkNetworkConnection()),
        setHeartbeat: (fire_time) => dispatch(actions.setHeartbeat(fire_time)),
        setEditorView: (data) => dispatch(actions.setEditorView(data)),
	};
};

const mapStateToProps = state => {
	return {
        interview_id:state.interview.interview_id,
        interview_status:state.interview.status,
        interview_title:state.interview.title,
        interview_role: state.interview.role,
        participants: state.interview.participants,
        start_time: state.interview.start_time,
        role: state.interview.role,
        user_id: state.auth.user_id,
        chat_data: state.events.chat_data,
        last_editor_data: state.events.last_editor_data,
        last_language: state.events.last_language,
        supported_languages: state.events.supported_languages || [],
        heartbeat: state.events.heartbeat,
        editor_view: state.meeting.editor_view
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewContainer));
