import { createAction } from "redux-action";
import axios from "axios";
import { AxiosCallError, AxiosCallRequest, AxiosCallSuccess, showErrorPage, WSConnected, WSDisconnected } from "./commonActions";
import { get_service_endpoint } from "../../ServiceEndpoints.js";
import { history } from "../../index"
import { Modal } from 'antd'
import { selectedTab } from "../reducers/AssessmentReducer";
import { setAssessment } from "./InviteActions";
import { logoutRequest } from "./AuthActions";
import { webSocket } from 'rxjs/webSocket';

let ep = get_service_endpoint('assessment')

export const resetTestCaseResultStatus = createAction("RESET_TC_RES_STATUS")
export const setCustInpResults = createAction("SET_CUSTOM_INP_RESULTS")
export const resetCustInpResults = createAction("RESET_CUSTOM_INP_RESULTS")

//sets the tab being viewed - prob desc, output or testcases
export const chooseOption = createAction("CHOOSE_OPTION");

export const SetInviteCredential = createAction("SET_INVITE_CREDENTIAL");

export const startTestSuccess = createAction("START_TEST_SUCCESS")

export const updateAssessment = createAction("UPDATE_ASSESSMENT")

export const assignLogo =createAction("ASSIGN_LOGO");
export const updateStatus =createAction("UPDATE_STATUS");

export const assignTestCaseData = createAction("ASSIGN_TESTCASE_DATA");
export const updateTestCaseData = createAction("UPDATE_TESTCASE_DATA");
export const addToExecutionList = createAction("ADD_TO_EXECUTION_LIST")
export const removeFromExecutionList = createAction("REMOVE_FROM_EXECUTION_LIST")


//selects a single problem to work on
export const assignProblem = createAction("ASSIGN_PROBLEM");
export const assignTestcases = createAction("ASSIGN_TESTCASES");
export const assignTemplate = createAction("EDITOR_ASSIGN_TEMPLATE");

//removes all the base code set in ASSIGN_TEMPLATES action
export const resetAll = createAction("EDITOR_RESET_ALL");

export const markAttempted = createAction("MARK_ATTEMPTED")

export const setTimerExpLevel = createAction("SET_TIMER_EXP_LEVEL")

export const fetchAssessment = (assessment_id, problem_id) => {
	return (dispatch, getState) => {
		dispatch(AxiosCallRequest());
		axios.get(
			`${ep}/api/assessments/${assessment_id}/`
		).then(
			response => {
				if (response.data.status === 'CM') {
					dispatch(showErrorPage({
						'errorType': 'Application Error',
						'errorHeading': 'Assessment Complete',
						'errorMessage': 'This assessment is complete and can no longer be viewed'
					}))
					history.push('/error')
				} else {
					dispatch(updateAssessment(response.data))
					dispatch(setAssessment(response.data))
					dispatch(fetchTestCasesData(response.data))
					dispatch(selectProblem(problem_id, false))
				}
				dispatch(AxiosCallSuccess()) 
			}
		).catch(
			error => {
				if (error.response) {
					if (error.response.status === 401) {
						Modal.error({
							title: 'Unauthorized access',
							content: 'Please make sure you are logged in as the user who received the invite'
						})
					}
				}
				dispatch(AxiosCallError())
			}
		)
	}
}

const assignLogoAndStatus = ({id, logo, pending_tc}) => {
	return (dispatch) => {
		let logo_list = Object.values(logo)
		let passCases = logo_list.filter(logo => logo === 'AC').length
		let totalCases = logo_list.length
		let notAttempted = logo_list.filter(logo => ["UA","NA"].includes(logo)).length
		let failedCases = logo_list.filter(logo => ["TLE","WA","CE","RE"].includes(logo)).length
		let status = ""
		if (pending_tc === 0) {
			if (passCases === totalCases) {
				status = "ALL_PASS"
			} else if (notAttempted === totalCases) {
				status = "NA"
			} else if (failedCases === totalCases) {
				status = "ALL_FAIL"
			} else if (passCases > 0) {
				status = "SOME_PASS"
			}
		}
		dispatch(assignLogo({id,logo,status}));
	}
}

export const fetchTestCasesData = (res) => {
	return(dispatch) => {
		res.problems.forEach(prob => {
			if (prob.codesubmission_id && prob.codesubmission_id !== null) {
				dispatch(fetchTestCaseExecResults(prob.id, prob.codesubmission_id))
			} else {
				let tc_logo = {}
				prob.testcases.forEach(t=>{
					tc_logo[t.id] = 'NA'
				})
				dispatch(assignLogoAndStatus({id:prob.id,logo:tc_logo}));
			}
		});
	}
}

export const selectProblem = (problem_id, hist_push=true) => {
	return (dispatch, getState) => {
			dispatch(assignProblem({problem_id: problem_id}));
			dispatch(chooseOption({
				activeTab: selectedTab.problemStatement,
				problem_id: problem_id,
			}))
			if (hist_push) {
				history.push(`/assessment/${getState().assessment.id}/problem/${problem_id}`)
			}
		};
};

export const submitAssessment = (assessmentId, redirect=true) => {
	
	return (dispatch, getState) => {
		dispatch(AxiosCallRequest());
		const submitCodePromises = []
		getState().assessment.problems.forEach(problem => {
		if (!problem.attempted && problem.editor && problem.editor.isDirty) {
		  problem.templates.forEach(template => {
			if (problem.editor.isDirty[template.language]) {
			  let data = {
				assessment_id: assessmentId,
				problem_id: problem.id,
				code_body: problem.editor.code[template.language]
				  .replace(template.header, "")
				  .replace(template.tailer, ""),
				language: template.language,
				cs_type: "regular",
				auto_submitted: true
			  };
			  submitCodePromises.push(axios.post(`${ep}/api/codesubmissions/`, data));
			}
		  });
		}
	  });
	  
  	Promise.all(submitCodePromises).finally(() => {
		const body ={}
		axios.post(
			`${ep}/api/assessments/${assessmentId}/end_assessment/`,
			body
		).then(
			response => {
				dispatch(AxiosCallSuccess())
				if (window.webSocketObj) // if WS connection exists close it
					window.webSocketObj.unsubscribe()
				if (redirect) {
					history.push("/testcomplete")
				}
				dispatch(logoutRequest())
				dispatch(setAssessment({"id":assessmentId,"status":"CM"}))
			}
		).catch(
			error => {
				if (error.response) {
					if (error.response.status === 401) {
						Modal.error({
							title: 'Unauthorized access',
							content: 'Please make sure you are logged in as the user who received the invite'
						})
					}
					if (error.response.status === 400) {
						Modal.error({
							title: 'An error occured',
							content: error.response.data.msg
						})
					}
				}
				dispatch(AxiosCallError())
			}
		)
	 });
	}
}

export const fetchCustomInputExecResults = (code_submission_id, max_tries=5) => {
	return dispatch => {
		const url = `${ep}/api/codesubmissions/${code_submission_id}/tc_executions/`;
		axios.get(url)
		.then(
			res => {
				let ciResult;
				if (res.data.length > 0)
					ciResult = res.data[0]
				if ((res.data.length === 0 && max_tries > 0) || (ciResult && ciResult.status === 'EX' && max_tries > 0)) {
					window.setTimeout(
						() => { dispatch(fetchCustomInputExecResults(code_submission_id, max_tries-1)) },
						2500
					)
				} 
				else {
					dispatch(setCustInpResults(res.data[0]))
					dispatch(AxiosCallSuccess())
				}
			}
		)
	}
}

export const fetchTestCaseExecResults = (prob_id, code_submission_id, max_tries=10) => {
	return (dispatch, getState) => {
		const url = `${ep}/api/codesubmissions/${code_submission_id}/tc_executions/`;
		axios.get(url)
		.then(
			res => {
				let tc_logo = {};
				let tc_executionData={};
				res.data.forEach(
					tcResult => {
						tc_executionData[tcResult.testcase_id] = {
							compiler_error: tcResult.compiler_error,
							runtime_error: tcResult.runtime_error,
							stderr: tcResult.stderr,
							timelimitexceeded_error: tcResult.timelimitexceeded_error
						}
						if(tcResult.compiler_error) {
							tc_logo[tcResult.testcase_id] = 'CE'
						}
						else if(tcResult.runtime_error) {
							tc_logo[tcResult.testcase_id] = 'RE'
						}
						else if(tcResult.timelimitexceeded_error) {
							tc_logo[tcResult.testcase_id] = 'TLE'
						}
						else {
							if(tcResult.status==='SC') {
								tc_logo[tcResult.testcase_id] = 'AC'
							}
							else if (tcResult.status==='EX') {
								tc_logo[tcResult.testcase_id] = 'LOAD'
							}
							else if (tcResult.status==='FL') {
								tc_logo[tcResult.testcase_id] = 'WA'
							}
							else {
								tc_logo[tcResult.testcase_id] = 'UA'
							}
						}
					}
				)
				// executed when we refresh the page and fetch last codesubmission.
				// If it is of type custom_input do not do anything.
				if (res.data.length === 1 && res.data[0].cs_type === 'custom_input') {
					return;
				}
				else {
					let problems = getState().assessment.problems
					let num_testcases = problems.find(p => p.id === prob_id).testcases.length
					let pending_tcs = res.data.filter(tcResult => tcResult.status==='EX').length + (num_testcases - res.data.length)
					dispatch(assignLogoAndStatus({id:prob_id, logo:tc_logo, pending_tc:pending_tcs}));
					dispatch(assignTestCaseData({id:prob_id,tc_executionData}));
					if (pending_tcs > 0 && max_tries > 0) {
						window.setTimeout(
							() => { dispatch(fetchTestCaseExecResults(prob_id, code_submission_id, max_tries-1)) },
							2500
						)
					}else{
						dispatch(WSDisconnected())
					}
				}
			}
		)
	}
}

export const submitCode=(data) => {
	return (dispatch, getState) => {
		let wsep = get_service_endpoint("common-ws") 
		dispatch(AxiosCallRequest());
		let urlStub = window.location.hostname.split('.').reverse();
		let rootDomain = '.' + urlStub[1] + '.' + urlStub[0];	
		// setting domain cookie for ws authentication
		document.cookie = "access_token=" + localStorage.getItem("access-token") + ";path=/;domain="+rootDomain;
		document.cookie = "refresh_token=" + localStorage.getItem("refresh-token") +  ";path=/;max-age=60;domain="+rootDomain;
		let WSConnectionStatus = getState().global.WSConnectionStatus
		// connect to ws if not connected already
		if (!WSConnectionStatus) {
			let ws = webSocket({
				url: `${wsep}/submit_code/?assessment_id=${data.id}`,
				openObserver: {
					next: () => {
						dispatch(WSConnected())
						dispatch(postCodeSubmission(data))
					}
				}
			});
			window.webSocketObj = ws
			let executionResults = [] // store all web socket messages for code executions
			ws.subscribe(message => {
				if (message.type === "code_executed") {
					if (message.data.cs_type === "regular") {
						// for regular code executions
						dispatch(AxiosCallSuccess())
						dispatch(updateTestCaseExecResults(message.data))
					}
					executionResults.push(message.data)
					dispatch(handleExecutionResults(executionResults))
				}
			}, err => {
				//error in connecting to WS
				Modal.warning({
					title: "Unable to fetch results",
					content: "An error occured while fetching the result, please refresh the page to fetch the results again.",
					okText: "Refresh page",
					onOk: () => {
						window.location.reload()
					}
				})
			})
		}
	};
};

const postCodeSubmission = (data) => {
	return (dispatch, getState) => {
		const url = `${ep}/api/codesubmissions/`;
		const body ={
			"assessment_id":data.id,
			"problem_id": data.problem_id,
			"code_body": data.code_body,
			"language": data.language,
			"cs_type": data.cs_type
		}
		if (data.cs_type==='custom_input')
			body['ci_stdin'] = data.ci_stdin
		else {
			dispatch(resetTestCaseResultStatus({logo: 'LOAD'})) 
		}
		// submit code
		let prob = getState().assessment.problems.find(p => p.id === data.problem_id)
		let num_testcases;
		if (data.cs_type === "custom_input")
			num_testcases = 1 // custom input
		else	
			num_testcases = prob.testcases.length // regular submission
		axios.post(url,body)
		.then(response => {
			// add to execution list
			let payload = {
				"codesubmission_id": response.data.id,
				"problem_id": prob.id,
				"cs_type": data.cs_type,
				"num_testcases": num_testcases
			} 
			dispatch(addToExecutionList(payload)) // adds the codesubmission to execution list
			if (data.cs_type === 'regular') {
				dispatch(chooseOption({
					activeTab: selectedTab.results,
					problem_id: data.problem_id
				}))
			}
			let curr_timer = sessionStorage.getItem("curr_timer") // get existing timeout ID if any
			sessionStorage.removeItem("curr_timer")
			window.clearTimeout(curr_timer) // clear existing timeout
			setTimeout(() => {
				const assessment = getState().assessment;
				const currProblem = assessment.problems.filter((problem)=>problem.isCurrent)[0];
				let loading = currProblem.testcases?.some(tc => tc.logo === 'LOAD');
		        if(loading){
					dispatch(fetchTestCaseExecResults(currProblem.id, assessment.executionList?.[assessment.executionList?.length-1].codesubmission_id, 6));
                }
			}, 5000); 
			let timer = window.setTimeout(() => {
				dispatch(handleExecutionTimeout(timer));
			}, 20000);// add new timeout
			sessionStorage.setItem("curr_timer", timer) // persist new timeout
		})
		.catch(err => {
			// error in submitting the code
			Modal.warning({
				title: "Unable to submit the code",
				content: "An error occured in submitting the code, please try again in some time."
			})
			dispatch(AxiosCallSuccess())
		})
	}
}

const handleExecutionTimeout = (timer) => {
	return (dispatch, getState) => {
		window.clearTimeout(timer) // clear the timeout
		let executionList = getState().assessment.executionList
		// get problems where not all testcases are passed
		let problems = getState().assessment.problems.filter(p => !p.status || p.status !== "ALL_PASS")
		// array of problem_ids
		problems = problems.map(p => p.id)
		let WSConnectionStatus = getState().global.WSConnectionStatus
		if (executionList.length > 0 && WSConnectionStatus) { // only show modals if WS is connected, otherwise show page refresh modal
			let nextProblemToSwitch;
			let problemsInExecution = executionList.map(ex => ex.problem_id) //problem IDs in execution
			let availableProblems = problems.filter(p =>!problemsInExecution.includes(p)) // problems IDs not in execution list
			if (availableProblems.length > 0) {
				// select a random problem ID out of available problems
				nextProblemToSwitch = availableProblems[Math.floor(Math.random()*availableProblems.length)];
				Modal.confirm({
					title: "Execution taking longer to complete",
					content: "The system is taking longer than usual to execute the code, you can either wait for results or solve another problem in the meantime.",
					okText: "Wait for result",
					cancelText: "Try another problem",
					onCancel: () => {
						dispatch(AxiosCallSuccess())
						// switch to another problem
						dispatch(selectProblem(nextProblemToSwitch))
					}
				})
			}
			else {
				// no problems left to switch
				Modal.info({
					title: "Execution taking longer to complete",
					content: "The system is taking longer than usual to execute the code, please try again after some time"
				})
				dispatch(AxiosCallSuccess())
			}

		}
	}
}

const updateTestCaseExecResults = (data) => {
	return dispatch => {
		let probId = data.problem_id
		let tcResult = data.tc_result
		let tcLogo;
		let tcExecutionData={};
		tcExecutionData = {
			id: tcResult.testcase_id,
			compiler_error: tcResult.compiler_error,
			runtime_error: tcResult.runtime_error,
			stderr: tcResult.stderr,
			timelimitexceeded_error: tcResult.timelimitexceeded_error
		}
		if(tcResult.compiler_error)
			tcLogo = 'CE'
		else if(tcResult.runtime_error)
			tcLogo = 'RE'
		else if(tcResult.timelimitexceeded_error)
			tcLogo = 'TLE'
		else {
			if(tcResult.status==='SC')
				tcLogo = 'AC'
			else if (tcResult.status==='FL')
				tcLogo = 'WA'
			else
				tcLogo = 'UA'
		}
		tcExecutionData["logo"] = tcLogo
		dispatch(updateTestCaseData({id:probId,tcExecutionData}));
	}
}

const handleExecutionResults = (executionResults) => {
	return (dispatch, getState) => {
		let executionList = getState().assessment.executionList
		if (executionList.length > 0) {
			let lastTcResult = executionResults[executionResults.length - 1].tc_result // last item in executionResults. executionResults hold WS messages
			let lastTcResultCodeSubmissionId = lastTcResult.code_submission_id // codesubmission id of last item in executionResults
			let currExecutingItem = executionList.find(ex => ex.codesubmission_id === lastTcResultCodeSubmissionId) // current executin item in execution list
			let numExecutionResult = executionResults.filter( exr => exr.tc_result.code_submission_id === lastTcResultCodeSubmissionId).length
			if (currExecutingItem.num_testcases === numExecutionResult) { // for current executing item all test cases are recieved by WS
				if (currExecutingItem.cs_type === "regular") {
					dispatch(markAttempted({problem_id: currExecutingItem.problem_id}))
					dispatch(updateAttempStatus(currExecutingItem.problem_id, executionResults))
					dispatch(handleWSDisconnect(lastTcResultCodeSubmissionId))
				}
				else if (currExecutingItem.cs_type === "custom_input") {
					dispatch(setCustInpResults(lastTcResult))
					dispatch(handleWSDisconnect(lastTcResultCodeSubmissionId))
					dispatch(AxiosCallSuccess())
				}
			}
		}
	}
}

const handleWSDisconnect = (codesubmission_id) => {
	// removes a problem from execution list and checks if it was in the last problem in execution list. If yes, then disconnect WS.
	return (dispatch, getState) => {
		const promise = new Promise((resolve, reject) => {
			dispatch(removeFromExecutionList(codesubmission_id))
			resolve(codesubmission_id)
		})
		promise.then(res => {
			let executionList = getState().assessment.executionList
			if (executionList.length === 0) { // execution list is empty, now we can close the websocket connection
				window.webSocketObj.unsubscribe()
				delete window.webSocketObj
				dispatch(WSDisconnected())
			}	
		})
	}
}

const updateAttempStatus = (prob_id, data) => {
	return dispatch => {
		// compute question attempt status
		let passCases = data.filter(item => item.tc_result.status === 'SC').length
		let failedCases = data.filter(item => item.tc_result.compiler_error || item.tc_result.runtime_error || item.tc_result.timelimitexceeded_error || item.tc_result.status === 'FL').length
		let totalCases = data.length
		let notAttempted = totalCases - passCases - failedCases
		
		let status = ""
		if (passCases === totalCases)
			status = "ALL_PASS"
		else if (notAttempted === totalCases)
			status = "NA"
		else if (failedCases === totalCases)
			status = "ALL_FAIL"
		else if (passCases > 0)
			status = "SOME_PASS"
		dispatch(updateStatus({id: prob_id, status:status}))
	}
}

export const postEvent = (data) => {
	return (dispatch, getState) => {
		data['assessment_id'] = getState().invite.assessment_id
		const url = `${ep}/api/events/`;
		axios.post(url, data)
		.then(res => {
		})
	  	.catch(err => {
			dispatch(AxiosCallError(err))
		})
	}
}
