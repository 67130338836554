import React, { Component } from "react";
import { Select, Icon, Button, Switch, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom'
import * as actions from "../../store/actions"
import { connect } from "react-redux"

import "./EditorHeader.scss"
import 'font-awesome/css/font-awesome.min.css';

const { Option } = Select;

//allows the user to change language
class InterviewHeader extends Component {

	toggleSync = (val) => {
		this.props.syncCursor(val)
	}

	setCodeEditorView = () => {
		if (this.props.editor_view !== "editor")
			this.props.setEditorView("editor")
	}

	setScreenShareView = () => {
		if (this.props.editor_view !== "screen-share")
			this.props.setEditorView("screen-share")
	}

	render() {
		const langDescMap = {
			python3: 'Python 3',
			ruby3: 'Ruby 3',
			python2: 'Python 2',
			javascript: 'Javascript',
			cpp: 'C++',
			java: 'Java',
			c: 'C'
		}
		let options = this.props.languageList.map((l,index) => (<Option key={index} value={l}>{langDescMap[l]}</Option>))
		let codeIconColor = this.props.editor_view === "editor"?"#2FB9E8":"grey"
		let screenShareIconColor = this.props.editor_view === "screen-share"?"#2FB9E8":"grey"
	
		return(
			<div styleName="EditorHeader">
				<div styleName="headerLeft">
					<Tooltip placement="topLeft" title="Code editor" mouseEnterDelay={0.5}>
						<Icon type="code" style={{fontSize: "2rem", marginRight: "2vw", cursor: "pointer", color: codeIconColor }} onClick={this.setCodeEditorView}/>
					</Tooltip>
					<Tooltip placement="topLeft" title="Screen share" mouseEnterDelay={0.5}>
						<Icon type="desktop" style={{fontSize: "2rem", cursor: "pointer", color: screenShareIconColor}} onClick={this.setScreenShareView}/>
					</Tooltip>
				</div>
				<div styleName="headerRight">
					{this.props.editor_view === "editor" && <Select value={this.props.selectedLanguage} id="menuList" style={{ width: "8vw", fontSize: "0.8vw" }} onChange={(e) => this.props.switchLanguage(e)} >
						{options}
					</Select>}		
					{this.props.editor_view === "editor" && <div>
						<span style={{fontSize:"1rem", marginRight: "0.5vw"}}>Sync cursor</span>
						<Switch onChange={this.toggleSync} />
					</div>}
				</div>
				{this.props.editor_view === "editor" && <Button
				styleName="form__button"
				onClick={() =>
					this.props.submit()
				}
				>
					Run Code
				</Button>}
				{this.props.editorExpand ? <div styleName="toggleExpandButton" onClick={()=> this.props.toggleExpand()}><Icon type="fullscreen-exit" /> </div>: <div styleName="toggleExpandButton" onClick={()=> this.props.toggleExpand()}> <Icon type="fullscreen" /></div>}
			</div>
		)
	}
}

const mapDispatchToProps = dispatch => {
	return {
		setEditorView: (state) => dispatch(actions.setEditorView(state)) 
	};
};

const mapStateToProps = state => {
	return {
		participants: state.interview.participants,
		editor_view: state.meeting.editor_view,
	};
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewHeader));