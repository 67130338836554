import React from "react"
import { withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import Timer from 'react-compound-timer'
import { GoogleLogin } from "react-google-login"
import { loginUsingGoogleForInterview } from '../../store/actions/AuthActions'
import { isCookieExpired, interviewRoleMap } from "../../helperFunctions"
import { get_image_endpoint } from '../../ImageEndpoint'
import * as actions from "../../store/actions/InterviewInviteActions"
import * as interviewActions from "../../store/actions/InterviewActions"
import { isChrome, isFirefox } from 'react-device-detect'
import { Modal, Layout, Icon } from 'antd';
import WrappedInterviewerLoginForm from './InterviewerLoginForm'
import { history } from "../../index";
import './InterviewInviteUIContainer.scss'

const { Content } = Layout;


class InterviewInviteUIContainer extends React.Component {

    constructor(props){
        super(props)
        let urlparams = new URLSearchParams(this.props.location.search)
		this.state = {
			interview_id: urlparams.get("interview_id"),
            hash_value: urlparams.get("hash_value"),
            user_role: urlparams.get("role"),
            company_id: urlparams.get("company_id"),
            showLogin: false
        }
    }

    componentDidMount() {
        if (!localStorage.getItem("interview_id"))
            localStorage.setItem("interview_id",this.state.interview_id)
        this.props.fetchInterviewInvite(this.state.interview_id,this.state.hash_value, this.state.user_role,this.state.company_id,false,() => this.callback())
    }

    callback = () => { 
        let urlparams = new URLSearchParams(this.props.location.search)
        if (localStorage.getItem("access-token") && !isCookieExpired() ) {
            history.push("/interview?interview_id="+ urlparams.get("interview_id"))
        }
    }     

    handleGoogleResponse = (response) => {
        if (response.error==='idpiframe_initialization_failed') {
			var url, option;
			if (isChrome)
			{
				 url = 'chrome://settings/cookies'
				 option = 'Select the option - Allow all cookies'
			}
			else if (isFirefox)
			{
				 url = 'about:preferences#privacy'
				 option = 'Select the option - Standard'
			}
			else {
				 url = null
				option = null
			}
			Modal.error({
				title: 'Browser cookies disabled',
				content: (
					<div>
							<p>Oops! Your browser seems to have 3rd party cookies disabled. Enable cookies to continue with the test.</p>
							{ url?(
									<div>
										<br></br>
										<p>
											In a new tab, paste the following in the browser:
											<p><strong>{url}</strong></p>
											{option}
										</p>
										<br></br>
									</div>
									):
									(<div><br></br></div>) }
							<p>Make sure that block third-party cookies is disabled. After enabling the cookies, refresh the test page, and then you will be able to sign in with your email id.</p>
					</div>
				),
				okText: 'Refresh Page',
				width:'450px',
				onOk() {
					window.location.reload(false);
				},
			});
		}
		const payload = {
            provider: "google",
            company_id: this.state.company_id,
            code: response.code,
            callback: () => this.props.startInterview(this.state.interview_id)
		};
		this.props.loginUsingGoogleForInterview(payload);
    }

    showLoginButton = () => {
        this.setState({
            showLogin:true
        })
    }


    render() { 

        if(this.props.start_time!==undefined){
            var curr_date = new Date().getTime()/1000
            var interview_date = new Date(this.props.start_time);
            interview_date=(interview_date.getTime())/1000 
            var remainingTime = interview_date-curr_date
        }

        if(this.props.participants) {
            var developer = this.props.participants.filter(e => e.role==='IN')
            var candidate = this.props.participants.filter(e => e.role==='CA')
        }
        
        if(remainingTime && remainingTime<=0 && !this.state.showLogin)
            this.showLoginButton()

        if(remainingTime && remainingTime<0)
            remainingTime=0
        return(
            <div styleName="c-interview-invite">
                {this.state.user_role === "CA" ?
                (<React.Fragment>
                    <div styleName="c-interview-invite__heading">
                        { this.props.company_details.alias &&
                        <div styleName="c-invite__logo">
                            <img src={get_image_endpoint(this.props.company_details.alias, "light")} alt={this.props.company_details.name+" logo"}/>
                        </div>
                        }
                        <div styleName="c-interview-invite__title">
                            <h5>CODING INTERVIEW</h5>
                        </div>
                    </div>   
                    <div styleName="c-interview-invite__name">
                <p>Interview with {developer ? developer[0].name : ""}</p>
                    </div>
                    <div styleName="c-interview-invite__timer">
                        {remainingTime!==undefined ? 
                        <Timer
                            initialTime={remainingTime*1000}
                            direction="backward"
                            formatValue={(value) => { return `${(value < 10 ? `0${value}` : value)}`}}
                            checkpoints = {[
                                {
                                    time: 0,
                                    callback: () => this.showLoginButton()
                                }
                            ]}
                        >
                            {() =>
                                (
                                <React.Fragment>
                                    <div styleName="c-interview-invite__details__days"><p><Timer.Days /></p> <p styleName="c-interview-invite__details-tag">Days</p></div> 
                                    <div styleName="c-interview-invite__details__hours"><p><Timer.Hours /></p> <p styleName="c-interview-invite__details-tag">Hours</p></div> 
                                    <div styleName="c-interview-invite__details__minutes"><p><Timer.Minutes /></p><p styleName="c-interview-invite__details-tag">Minutes</p></div> 
                                    <div styleName="c-interview-invite__details__seconds"><p><Timer.Seconds /></p><p styleName="c-interview-invite__details-tag">Seconds</p></div>
                                </React.Fragment>
                            )}
                        </Timer> : null}
                    </div>
                    {/* <div>
                        <Alert message="The timer is up. You can login now." type="error" />
                    </div> */}  
                    <div styleName="c-interview-invite__login">
                        {this.state.showLogin ? 
                        (<GoogleLogin
                            clientId="73660977592-m50kcgualicen86vokvsbg3vsh8h6c2e.apps.googleusercontent.com"
                            buttonText="Sign in with Google"
                            responseType="code"
                            onSuccess={response => this.handleGoogleResponse(response)}
                            onFailure={response => this.handleGoogleResponse(response)}
                        />) : null 
                        }
                    </div> 
                </React.Fragment>
                ) : 
                <div styleName="login__form">
				<Layout styleName="login">
                    <Content styleName="login__content">
                        <div styleName="login__form__content">
                            { this.props.company_details.alias &&
                            <div styleName="login__logo">
                                <img src={get_image_endpoint(this.props.company_details.alias, "light")} alt={this.props.company_details.name+" logo"} style={{width:'100%'}} />
                                <h5 style={{paddingLeft:"0px", fontSize:"1.45rem"}}>CODING INTERVIEW</h5>
                            </div>
                            }
                            <hr style ={{width:"75%", borderTop:"0.0613vw solid #1890FF"}}/>
                            <div styleName="login__candidateinfo">
                                <div styleName="candidateinfo__user">
                                    <Icon type="user" style={{fontSize:"1.2vw", marginRight:"0.6vw"}} />
                                    <p><span style={{fontWeight:"bold"}}>Candidate: </span>{candidate ? candidate[0].name : ""}</p>
                                </div>
                                <div styleName="candidateinfo__role">
                                    <Icon type="setting" style={{fontSize:"1.2vw", marginRight:"0.6vw"}} />
                                    <p><span style={{fontWeight:"bold"}}>Role: </span>{this.props.interview_role? interviewRoleMap[this.props.interview_role]:""}</p>
                                </div>
                            </div>
                            <hr style={{width:"75%", borderTop:"0.0613vw solid #1890FF"}} />
                            <h5 styleName="candidate__sign-in">Sign in</h5>
                            <div>
                            <WrappedInterviewerLoginForm />
                            </div>
                        </div>
                    </Content>		
			    </Layout>
                </div>
                }
            <div styleName="c-interview-invite__footer">
            <p>
                {`Copyright © ${new Date().getFullYear()} CodeHall Technology Pvt Ltd. All rights reserved.`}
            </p>
            </div>  
        </div>  
        )
    }
}

const mapDispatchToProps = dispatch => {
	return {
        loginUsingGoogleForInterview: payload => dispatch(loginUsingGoogleForInterview(payload)),
        fetchInterviewInvite: (interview_id, hash_value, role, company_id, fetch_interview_data, callback) => 
            dispatch(actions.fetchInterviewInvite(interview_id, hash_value, role, company_id, fetch_interview_data, callback)),
        startInterview: (interview_id) => dispatch(actions.startInterview(interview_id)),
        logout: () => dispatch(interviewActions.logout())
	};
};

const mapStateToProps = state => {
	return {
        interview_id:state.interview.interview_id,
        interview_status:state.interview.status,
        interview_title:state.interview.title,
        interview_role: state.interview.role,
        participants: state.interview.participants,
        start_time: state.interview.start_time,
        role: state.interview.role,
        user_id: state.auth.user_id,
        company_details: state.auth.company_details
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InterviewInviteUIContainer));