import React from 'react';
import { ContentShare, useContentShareState } from "amazon-chime-sdk-component-library-react"
import "./ScreenShare.scss"
import { useDispatch } from 'react-redux';
import { setEditorView} from '../../../../store/actions';

const ScreenShareView = () => {
    const dispatch = useDispatch()
    const {tileId} = useContentShareState()
    if (!tileId) {
        dispatch(setEditorView("editor"))
    }
    return(
            <ContentShare css="height: 29.5vw !important; border: 0.063vw solid black"/>
    )
}

export default ScreenShareView