import React from "react"
import ViewNotes from '../Notes/ViewNotes'
import EmptyNotes from '../../../components/Interview/EmptyNotes'
import { Row, Col } from "antd";
import WrappedFeedbackForm from "./FeedbackForm"
import * as actions from "../../../store/actions"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import { history } from "../../../index";
import { isCookieExpired } from "../../../helperFunctions"

import './FeedbackContainer.scss'
class FeedbackContainer extends React.Component {

	componentDidMount() {
		if (!localStorage.getItem("access-token") || isCookieExpired()){
			history.push(`/interviewinvite?invterview_id=${localStorage.getItem('interview_id')}
				&hash_value=${localStorage.getItem('hash_value')}&role=${localStorage.getItem('role')}
				&company_id=${localStorage.getItem('company_id')}`)
		}
		let urlparams = new URLSearchParams(this.props.location.search,this.props.history)
		this.props.getInterview(urlparams.get("interview_id")).then(res=> {
			if (!this.props.participants || this.props.participants.length === 0)
				history.push ('/interviewcomplete')
			else {
				let p = this.props.participants.find(obj => obj.role ==="IN" && obj.email === localStorage.getItem("username"))
				if (!p)
					history.push('/interviewcomplete')
				if (p.feedback)
					history.push ('/interviewcomplete')
			}
			this.props.fetchNotes(urlparams.get("interview_id"))
		})
	}
	
	render() {
		let noteViewSpan = 9;
		let feedbackSpan = 15;
		return (
			<div styleName="c-feedback">
				<Row
					gutter={16}
					style={{ marginRight: 0, marginLeft: "7vw", marginTop:"3vw"}}
				>
					<Col
						span={noteViewSpan}
						style={{ paddingRight: 0, paddingLeft: 0 }}
					>
						<div styleName="c-feedback__notes">
						   {this.props.notes && this.props.notes.length >0?
								<ViewNotes expand={true} interview_id={this.props.interview_id}/>
								:
								<EmptyNotes />
							}
						</div>
					</Col>
					<Col
						span={feedbackSpan}
						style={{ paddingRight: 0, paddingLeft: 0}}
					>
							<WrappedFeedbackForm />
					</Col>
				</Row>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		interview_id: state.interview.interview_id,
		notes: state.events.notes,
		participants: state.interview.participants,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		getInterview: (interview_id) => dispatch(actions.getInterview(interview_id)),
		fetchNotes: (interview_id) => dispatch(actions.fetchNotes(interview_id))
	};
};

export default  withRouter(connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer));