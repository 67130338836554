// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalHeader, useMeetingManager, Spinner, PrimaryButton } from 'amazon-chime-sdk-component-library-react';
import { interviewRoleMap, isInterviewDeveloper } from "../../../helperFunctions";
import { setCurrentInterviewPage, createRecording } from '../../../store/actions';
import { StyledWrapper, StyledAudioGroup, StyledVideoGroup } from './Styled';
import MicrophoneDevices from './MicrophoneDevices';
import SpeakerDevices from './SpeakerDevices';
import CameraDevices from './CameraDevices';
import Card from '../DevicePermissionCard'


const DeviceSelection = () => {

  const dispatch = useDispatch()
  const meetingManager = useMeetingManager()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { participants } = useSelector(state => state.interview)
  const { id } = useSelector(state => state.meeting)
  const { role } = useSelector(state => state.interview)

  const startMeeting = async () => {
      try {
          await meetingManager.start();
          setIsLoading(false);
          if (isInterviewDeveloper(participants))
            dispatch(createRecording(id))
          dispatch(setCurrentInterviewPage("meeting"))
      } catch (error) {
          setIsLoading(false);
          setError(error.message);
      }
  }

  return (
    <StyledWrapper>
      <StyledVideoGroup>
        <CameraDevices />
      </StyledVideoGroup>
      <StyledAudioGroup>
        <MicrophoneDevices />
        <SpeakerDevices />
        <p style={{marginTop: "2.75vw", fontSize: "1.25rem"}}>No one else is here</p>
        <PrimaryButton onClick={startMeeting} style={{marginTop: "2.75vw", fontSize: "1rem"}} label="Continue"/>
      </StyledAudioGroup>
      {isLoading && <Spinner />}
      {error && (
                <Modal size="md" onClose={() => setError('')}>
                <ModalHeader title={interviewRoleMap[role]+" Meeting"} />
                <ModalBody>
                    <Card
                    title="Unable to join meeting"
                    description="There was an issue in joining this meeting. Check your connectivity and try again."
                    smallText={error}
                    />
                </ModalBody>
                </Modal>
      )}
    </StyledWrapper>
  )
};

export default DeviceSelection;
